import { unverifyDocument } from 'Api/document';
import { useMutation } from 'react-query';

export const useUnverifyDocument = (acquireLockDocument, selectedAgentId, userToken) => {
  const { mutate: unverify } = useMutation(unverifyDocument, {
    onSuccess: () => {
      acquireLockDocument([selectedAgentId, userToken]);
    },
  });

  return { unverify };
};
