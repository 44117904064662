import Navbar from 'components/primitives/navbar';
import { PropTypes } from 'prop-types';

const MainLayout = ({ children, hasNavbar = true }) => {
  return (
    <div className="w-full" data-testid="test-main-layout">
      {hasNavbar && <Navbar />}
      <div>{children}</div>
    </div>
  );
};
MainLayout.propTypes = {
  hasNavbar: PropTypes.bool,
  children: PropTypes.any,
};

export default MainLayout;
