import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const getPreviewReviewSheet = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    `${apiEndoints.LIST_FILES}/${queryKey[1]}/PreviewReviewSheet`,
    { authorization: queryKey[2] },
    queryKey[3],
    10,
    'blob',
  );
};

getPreviewReviewSheet.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { getPreviewReviewSheet };
