import { getPreviewReviewSheet } from 'Api/previewReviewSheet';
import { useQuery } from 'react-query';

export const useGetPreviewReviewSheet = (fileId, userToken, params, enableFetching) => {
  const { isLoading, isFetching, data } = useQuery(
    ['GetPreviewReviewSheet', fileId, userToken, params],
    getPreviewReviewSheet,
    {
      staleTime: Infinity,
      enabled: enableFetching,
    },
  );
  return { isLoading, data, isFetching };
};
