import { LoginPage, FileListPage, FileReviewPage, Partners } from 'pages';
import { routePaths } from 'utils/constants';

const routes = [
  {
    path: routePaths.LOGIN_PAGE,
    component: <LoginPage />,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.FILE_LIST_PAGE,
    component: <FileListPage />,
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.FILE_REVIEW_PAGE,
    component: <FileReviewPage />,
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.PARTNERS,
    component: <Partners />,
    isGuarded: true,
    children: [],
  },
];

export default routes;
