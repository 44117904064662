import { listPartners } from 'Api/partners';
import { useQuery } from 'react-query';

export const useGetListPartners = (
  currentPage,
  searchQuery,
  userToken,
  enableFetching,
) => {
  const { isLoading, data, isError } = useQuery(
    ['ListPartners', currentPage, searchQuery, userToken],
    listPartners,
    {
      enabled: enableFetching,
    },
  );

  return { isLoading, data, isError };
};
