import { getPartner } from 'Api/partner';
import { useQuery } from 'react-query';

export const useGetPartner = (userToken, selectedPartnerId, enableFetchingPartner) => {
  const { data: dataPartner } = useQuery(
    ['GetPartner', userToken, selectedPartnerId],
    getPartner,
    {
      staleTime: Infinity,
      enabled: enableFetchingPartner,
    },
  );
  return { dataPartner };
};
