import { useEffect, useMemo, useState } from 'react';

const PDFViewer = ({ pdfData, setCurrentPage, setTotalPage, viewSDKClientComponent }) => {
  const viewerConfig = useMemo(
    () => ({
      showAnnotationTools: false,
      enableFormFilling: false,
      showDownloadPDF: false,
      showPrintPDF: false,
      showZoomControl: true,
      defaultViewMode: 'FIT_WIDTH',
      enableSearchAPIs: true,
    }),
    [],
  );
  const [viewSDKClient] = useState(viewSDKClientComponent);

  useEffect(() => {
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      const previewFilePromise = viewSDKClient.previewFile(
        'pdf-div',
        viewerConfig,
        pdfData,
      );

      previewFilePromise.then(adobeViewer => {
        adobeViewer.getAPIs().then(apis => {
          apis
            .getPDFMetadata()
            .then(result => setTotalPage(result.numPages))
            .catch(error => console.log(error));
        });
      });

      viewSDKClient.registerEventsHandler(setCurrentPage);
    });
  }, [pdfData, viewerConfig, setTotalPage, setCurrentPage, viewSDKClient]);

  return (
    <div data-testid="test-pdf-viewer" id="pdf-div" className="full-window-div h-full" />
  );
};

export default PDFViewer;
