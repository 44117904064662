import { listPartnerDocuments } from 'Api/partnerDocuments';
import { useQuery } from 'react-query';

export const useGetListPartnerDocuments = (
  partnerId,
  currentPage,
  userToken,
  enableFetching,
) => {
  const { isLoading, data, isError } = useQuery(
    ['ListPartnerDocuments', partnerId, currentPage, userToken],
    listPartnerDocuments,
    {
      enabled: enableFetching,
    },
  );

  return { isLoading, data, isError };
};
