import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  if (isAuthenticated) {
    // <Navigate to="/" replace/>
    return <div data-testid="test-is-authenticated">is Authenticated</div>;
  }

  return <div />;
};

export default LoginPage
