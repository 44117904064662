import { getCurrentUser } from 'Api/currentUser';
import { useQuery } from 'react-query';

export const useGetCurrentUser = (userToken, enableFetching) => {
  const { data } = useQuery(['GetCurrentUser', userToken], getCurrentUser, {
    staleTime: Infinity,
    enabled: enableFetching,
  });
  return { data };
};
