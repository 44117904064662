import { updateDocument } from 'Api/document';
import { useMutation } from 'react-query';

export const useUpdateDocument = (verify, selectedAgentId, userToken) => {
  const { mutate: update } = useMutation(updateDocument, {
    onSuccess: () => {
      verify([selectedAgentId, userToken]);
    },
  });

  return { update };
};
