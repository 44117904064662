import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const getCurrentUser = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    `${apiEndoints.USER}/CurrentUser`,
    { authorization: queryKey[1] },
    {},
    {},
  );
};

getCurrentUser.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { getCurrentUser };
