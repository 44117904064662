import React from 'react';
import PDFViewer from './PDFViewer';
import { PropTypes } from 'prop-types';
import { useGetPDF } from 'hooks';
const PDFComponent = ({
  documentId,
  currentPage,
  setCurrentPage,
  totalPage,
  setTotalPage,
  userToken,
  setHeightRightSidebar,
  viewSDKClientComponent,
}) => {
  const { isLoading, data, isError } = useGetPDF(userToken, documentId);

  return (
    <div className="h-full">
      {isLoading && (
        <span data-testid="test-loader" className="w-full flex justify-center">
          Files Loading...
        </span>
      )}
      {isError && (
        <span data-testid="test-error" className="w-full flex justify-center">
          An Error occured When fetching the data
        </span>
      )}
      {data?.data?.document && (
        <PDFViewer
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
          pdfData={data}
          setHeightRightSidebar={setHeightRightSidebar}
          viewSDKClientComponent={viewSDKClientComponent}
        />
      )}
    </div>
  );
};

PDFComponent.propTypes = {
  documentId: PropTypes.string,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPage: PropTypes.number,
  setTotalPage: PropTypes.func,
  userToken: PropTypes.string,
  viewSDKClientComponent: PropTypes.any,
  setViewerAPI: PropTypes.func,
};

export default PDFComponent;
