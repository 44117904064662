import { updatePartner } from 'Api/partner';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdatePartner = () => {
  const queryClient = useQueryClient();

  const { mutate: update } = useMutation(updatePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries('GetPartner');
      queryClient.invalidateQueries('ListPartners');
    },
  });
  return { update };
};
