import React from 'react';
import { ChevronIcon } from '../../assets';
import { PropTypes } from 'prop-types';

const Pagination = ({ totalPage, currentPage, setCurrentPage }) => {
  return (
    <div
      className="grid grid-cols-4 gap-2 h-44px"
      data-testid="test-right-sidebar-pagination"
    >
      <div
        className="col-span-1 flex justify-end items-center"
        data-testid="test-check-icon-left"
        onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
      >
        <div className="rotate-90" data-testid="test-check-icon">
          <ChevronIcon />
        </div>
      </div>
      <div className="col-span-2 flex justify-center items-center">{`Page ${currentPage}`}</div>
      <div
        className="col-span-1 flex justify-start items-center"
        data-testid="test-check-icon-right"
        onClick={() => currentPage < totalPage && setCurrentPage(currentPage + 1)}
      >
        <div className="rotate-90">
          <div className="rotate-180">
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalPage: PropTypes.number.isRequired,
};

export default Pagination;
