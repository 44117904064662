import { verifyDocument } from 'Api/document';
import { useMutation, useQueryClient } from 'react-query';

export const useVerifyDocument = () => {
  const queryClient = useQueryClient();

  const { mutate: verify } = useMutation(verifyDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries('GetFileReview');
      queryClient.invalidateQueries('ListFiles');
    },
  });

  return { verify };
};
