import { useEffect, useState } from 'react';
import { userTypes } from 'utils/constants';
import { perimissionProfiles } from 'utils/permission';
import Proptypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const useUser = userId => {
  //TODO: remove es-lint disable when useUser is conected to API and updating userType
  // eslint-disable-next-line
  const [userType, setUserType] = useState(userTypes.MASTER_USER);
  const [userToken, setUserToken] = useState();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        setUserToken(token);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  const userPermissionProfile = perimissionProfiles.find(
    profile => profile.type === userType,
  );

  return {
    userType,
    userPermissionProfile,
    userToken,
  };
};

useUser.propTypes = {
  userId: Proptypes.string,
  //TODO: add .isRequired once necessary
};

export default useUser;
