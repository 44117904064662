/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise(resolve => {
      if (window.AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
    this.reportView = undefined;
    this.previewPromise = undefined;
    this.state = { file: undefined };
  }

  ready() {
    return this.readyPromise;
  }

  base64ToArrayBuffer(pdfData) {
    var bin = atob(pdfData);
    var len = bin.length;
    var uInt8Array = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      uInt8Array[i] = bin.charCodeAt(i);
    }
    return uInt8Array.buffer;
  }

  goToViewedPage(previewFilePromise, currentPage) {
    previewFilePromise.then(adobeViewer => {
      adobeViewer.getAPIs().then(apis => {
        apis.gotoLocation(currentPage);
      });
    });
  }
  processEvent(event, setCurrentPage) {
    if (event.type === 'PAGE_VIEW') {
      setCurrentPage(event.data.pageNumber);
    }
  }

  previewFile(divId, viewerConfig, pdfData) {
    const config = {
      /* Pass your registered client id */
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
    };
    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          promise: Promise.resolve(
            this.base64ToArrayBuffer(pdfData?.data?.document?.documentBody),
          ),
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: 'BMA',
          /* file ID */
        },
      },
      viewerConfig,
    );

    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, viewerConfig, reportData) {
    /* Initialize the AdobeDC View object */
    const config = {
      /* Pass your registered client id */
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
    };
    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    this.reportView = new window.AdobeDC.View(config);

    const getBase64 = async file => {
      return new Promise(resolve => {
        let baseURL = '';
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };
    const file = reportData?.data;
    getBase64(file)
      .then(result => {
        file['base64'] = result;
        // console.log('File Is', file);
        const temp = file.base64;
        this.state.file = temp && temp.replace('data:application/pdf;base64,', '');

        const previewFilePromise = this.reportView.previewFile(
          {
            /* Pass information on how to access the file */
            content: {
              /* pass file promise which resolve to arrayBuffer */
              promise: Promise.resolve(this.base64ToArrayBuffer(this.state.file)),
            },
            /* Pass meta data of file */
            metaData: {
              /* file name */
              fileName: 'Report',
            },
          },
          viewerConfig,
        );
        return previewFilePromise;
      })
      .catch(err => {
        console.log(err);
      });
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData, content, options) => {
      console.log(metaData, content, options);
      return new Promise(resolve => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, { updatedAt: new Date().getTime() }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {},
    );
  }

  registerEventsHandler(setCurrentPage) {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      event => {
        this.processEvent(event, setCurrentPage);
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      },
    );
  }
}

export default ViewSDKClient;
