import React, { useState, useEffect } from 'react';
import { ChevronIcon, BanIcon } from '../../assets';
import { Label, TextInput } from 'flowbite-react';
import { PropTypes } from 'prop-types';
import { type } from './typeMapping';
import { CONFIDENCE_SCORE } from 'configs/confidence';
const SectionItem = ({
  section,
  formik,
  currentPage,
  sectionKey,
  values,
  isVerified,
  selectedAgentId,
  checkedLowConfidence,
  isOpenSection,
}) => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (isOpenSection) {
      setCollapse(true);
    } else setCollapse(false);
  }, [section.reference, selectedAgentId, isOpenSection]);

  const checkboxHandle = key => {
    return formik
      .getFieldProps(
        `contents[${currentPage - 1}].sections[${sectionKey}].elements[${key}].value`,
      )
      .value?.toLowerCase() === 'true'
      ? true
      : false;
  };

  const checkComboHandle = (itm, checked, key) => {
    // get all elements by parent index
    const items = document.getElementsByClassName(itm.index);

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!checked) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    }
    return formik.setFieldValue(
      `contents[${currentPage - 1}].sections[${sectionKey}].elements[${key}].value`,
      `${!checked}`,
    );
  };

  const convertData = ({ obj, findingKey, parentValue = {}, parentKey = '' }) => {
    let result = {};

    for (const key in obj) {
      const tempValue = obj[key];

      if (
        typeof tempValue === 'object' &&
        !Array.isArray(tempValue) &&
        tempValue !== null
      ) {
        const tempParentKey = Object.keys(tempValue).includes(findingKey)
          ? parentKey
          : key;
        if (findingKey === key) {
          return {
            ...result,
            value: tempValue,
            parentValue: parentValue,
            parentKey: parentKey,
          };
        } else {
          result = {
            ...result,
            ...convertData({
              obj: tempValue,
              findingKey,
              parentValue: obj,
              parentKey: tempParentKey,
            }),
          };
        }
      } else if (findingKey === key) {
        return {
          ...result,
          value: tempValue,
          parentValue: parentValue,
          parentKey: parentKey,
        };
      }
    }

    return result;
  };

  let sectionConverted = section?.elements?.map((itm, key) => {
    const typeItem = convertData({ obj: type, findingKey: itm.index });
    const confidence = itm.confidence ? itm.confidence : 1;
    return {
      ...itm,
      type: typeItem,
      className: typeItem.parentKey,
      confidence,
      checked: itm?.value?.toLowerCase() === 'true' ? true : false,
    };
  });

  sectionConverted.forEach(item => {
    if (item.confidence < CONFIDENCE_SCORE) {
      sectionConverted = sectionConverted.map(itm =>
        itm.index === item.type.parentKey ? { ...itm, confidence: 0 } : itm,
      );
    }
  });

  const willShowItem = item => {
    const { type } = item;
    const { parentValue } = type;

    const parentChecked = sectionConverted.find(
      el => el.index === item.className,
    )?.checked;

    if (parentValue.type === 'check_combo') {
      if (parentChecked) {
        return 'block';
      }
      return 'none';
    }
    return 'block';
  };

  const willHideItem = item => {
    if (item.confidence >= CONFIDENCE_SCORE && checkedLowConfidence) {
      return 'hidden';
    } else return 'block';
  };

  const handleCollapse = () => {
    if (checkedLowConfidence && !section.hasLowConfidence) {
      return;
    } else setCollapse(!collapse);
  };

  return (
    <>
      <div className="pr-5 pl-2 pt-2 pb-2 bg-gray-100" data-testid="test-section">
        <div
          className="flex justify-between items-center"
          onClick={handleCollapse}
          data-testid="test-section-item"
        >
          <p>{section?.sectionTitle}</p>
          {checkedLowConfidence && !section.hasLowConfidence ? (
            <BanIcon />
          ) : (
            <div className={`${collapse && 'rotate-180'}`}>
              <ChevronIcon />
            </div>
          )}
        </div>
      </div>
      <div>
        {collapse &&
          (checkedLowConfidence && !section.hasLowConfidence ? (
            <div className="flex justify-center p-3 text-gray-600">
              No fields to review
            </div>
          ) : (
            <div className="p-2" data-testid="test-section-item-input">
              {sectionConverted?.map((itm, key) => {
                const checked = checkboxHandle(key);
                return (
                  <div key={itm.index}>
                    {itm.type.value.type === 'group' && (
                      <div className={`mb-2 block ${willHideItem(itm)}`}>
                        <Label
                          htmlFor={itm?.displayText}
                          value={itm.type?.value?.label}
                        />
                      </div>
                    )}

                    {itm.type.value.type !== 'checkbox' &&
                      itm.type.value.type !== 'check_combo' &&
                      itm.type.value.type !== 'group' && (
                        <div className={`${willHideItem(itm)}`}>
                          <div
                            className={itm.className}
                            style={{ display: willShowItem(itm) }}
                          >
                            <div className="flex mb-2 block">
                              {itm.confidence < CONFIDENCE_SCORE && (
                                <div className="flex items-center">
                                  <span className="rounded-full bg-red-500 w-1.5 h-1.5 mr-1" />
                                </div>
                              )}
                              <Label
                                htmlFor={itm?.displayText}
                                value={itm?.displayText}
                              />
                            </div>

                            <TextInput
                              disabled={isVerified}
                              name={`contents[${
                                currentPage - 1
                              }].sections[${sectionKey}].elements[${key}].value`}
                              type="text"
                              placeholder="Value"
                              required={true}
                              value={
                                values.contents[currentPage - 1]?.sections[sectionKey]
                                  ?.elements[key]?.value || ''
                              }
                              onChange={formik.handleChange}
                            />
                            {itm?.haveUnderLine && <hr className="mt-2" />}
                          </div>
                        </div>
                      )}
                    {(itm.type.value.type === 'checkbox' ||
                      itm.type.value.type === 'check_combo') && (
                      <>
                        <div className={`flex items-center ${willHideItem(itm)}`}>
                          <input
                            data-testid="test-checkcombo-input"
                            id="list-radio-license"
                            type="checkbox"
                            name={`contents[${
                              currentPage - 1
                            }].sections[${sectionKey}].elements[${key}].value`}
                            disabled={isVerified}
                            checked={checked}
                            onChange={() => checkComboHandle(itm, checked, key)}
                            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <div className="flex">
                            {itm.confidence < CONFIDENCE_SCORE && (
                              <div className="flex items-center mr-1">
                                <span className="rounded-full bg-red-500 w-1.5 h-1.5" />
                              </div>
                            )}
                            <label
                              htmlFor="list-radio-license"
                              className="py-1 w-full text-sm text-gray-500 dark:text-gray-300"
                            >
                              {itm.displayText}
                            </label>
                          </div>
                        </div>
                        {itm?.haveUnderLine && <hr className="mt-2" />}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </>
  );
};
SectionItem.propTypes = {
  section: PropTypes.object,
  values: PropTypes.object,
  formik: PropTypes.any,
  currentPage: PropTypes.number,
  sectionKey: PropTypes.number,
  isVerified: PropTypes.bool,
};
export default SectionItem;
