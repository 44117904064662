import useUser from 'hooks/useUser';
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

const GuardedRoute = ({path}) => {
    const {userPermissionProfile} = useUser();

    const userIsAuthorized = userPermissionProfile.permittedRoutes.find(route => route === path);

    return userIsAuthorized ? <Outlet/> : <Navigate to={userPermissionProfile.defaultRedirectUrl}/>
}

GuardedRoute.propTypes = {
    path: PropTypes.string.isRequired
}

export default GuardedRoute;