import { releaseLock } from 'Api/document';
import { useMutation, useQueryClient } from 'react-query';

export const useReleaseLockDocument = () => {
  const queryClient = useQueryClient();

  const { mutate: releaseLockDocument } = useMutation(releaseLock, {
    onSuccess: () => {
      queryClient.invalidateQueries('GetFileReview');
      queryClient.invalidateQueries('ListFiles');
    },
  });

  return { releaseLockDocument };
};
