import { getFileReview } from 'Api/fileReview';
import { useQuery } from 'react-query';

export const useGetFileReview = (userToken, paramId, enableFetching) => {
  const { isLoading, data, isError } = useQuery(
    ['GetFileReview', userToken, paramId],
    getFileReview,
    {
      staleTime: Infinity,
      enabled: enableFetching,
    },
  );
  return { isLoading, data, isError };
};
