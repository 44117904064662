import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const verifyDocument = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.DOCUMENT}/${queryKey[0]}/Verify`,
    { authorization: queryKey[1] },
    {},
    10,
  );
};

verifyDocument.propTypes = {
  queryKey: PropTypes.string.isRequired,
};

const unverifyDocument = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.DOCUMENT}/${queryKey[0]}/Unverify`,
    { authorization: queryKey[1] },
    {},
    10,
  );
};

unverifyDocument.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

const updateDocument = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.DOCUMENT}/${queryKey[0]}`,
    { authorization: queryKey[1] },
    queryKey[2],
    10,
  );
};

updateDocument.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

const acquireLock = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.DOCUMENT}/${queryKey[0]}/AcquireLock`,
    { authorization: queryKey[1] },
    {},
    {},
  );
};

acquireLock.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

const releaseLock = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.DOCUMENT}/${queryKey[0]}/ReleaseLock`,
    { authorization: queryKey[1] },
    {},
    {},
  );
};

releaseLock.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { verifyDocument, unverifyDocument, updateDocument, acquireLock, releaseLock };
