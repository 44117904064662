import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const getPartner = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    `${apiEndoints.LIST_PARTNERS}/${queryKey[2]}`,
    { authorization: queryKey[1] },
    {},
    10,
  );
};

getPartner.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

const updatePartner = queryKey => {
  return execute(
    httpMethods.PUT,
    `${apiEndoints.LIST_PARTNERS}/${queryKey[0]}`,
    { authorization: queryKey[1] },
    queryKey[2],
    10,
  );
};

updatePartner.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { getPartner, updatePartner };
