import axios from 'axios';
import { httpMethods } from 'utils/constants';

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

const handlePagination = (data, DEFAULT_PAGE_SIZE) => {
  if (!data || (data.page == null && data.pageSize == null)) return data;

  const pageSize = data.pageSize || DEFAULT_PAGE_SIZE;
  const page = data.page || 0;
  const skip = ((page || 1) - 1) * pageSize;

  delete data.pageSize;
  delete data.page;

  return {
    ...data,
    skip,
    count: pageSize,
  };
};

const execute = (
  method,
  endpoint,
  headers = undefined,
  body = undefined,
  defaultPageSize,
  responseType = undefined,
) => {
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(headers.authorization && {
      authorization: generateAuthToken(headers.authorization),
    }),
  };

  if (method === httpMethods.GET) {
    body = handlePagination(body, defaultPageSize);
  }

  const requestConfig = {
    url: endpoint,
    method,
    ...(method === httpMethods.GET && { params: body }),
    data: body,
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    headers: requestHeaders,
    responseType,
  };

  return axios(requestConfig).catch(error => {
    if (error.response) {
      if (401 === error.response.status) {
        window.location.href = '/';
      }
    }
  });
};

const generateAuthToken = token => {
  return `Bearer ${token}`;
};

export { execute, generateAuthToken };
