import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const getPDF = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    `${apiEndoints.DOCUMENT}/${queryKey[2]}/pdf`,
    { authorization: queryKey[1] },
    {},
    10,
  );
};

getPDF.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { getPDF };
