import React, { useMemo } from 'react';
import { useEffect } from 'react';
import ViewSDKClient from './ViewSDKClient';

const Report = ({ reportData }) => {
  const viewSDKClient = useMemo(() => {
    return new ViewSDKClient();
  }, []);
  const viewerConfig = useMemo(
    () => ({
      showAnnotationTools: false,
      enableFormFilling: false,
      showDownloadPDF: false,
      showPrintPDF: false,
      showZoomControl: true,
      embedMode: 'FULL_WINDOW',
      defaultViewMode: 'FIT_WIDTH',
      enableSearchAPIs: false,
      showThumbnails: false,
    }),
    [],
  );
  useEffect(() => {
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFileUsingFilePromise('report-div', viewerConfig, reportData);
    });
  }, [reportData, viewerConfig, viewSDKClient]);

  return (
    <div className="in-line-container h-full">
      <div id="report-div" className="in-line-div" />
    </div>
  );
};

export default Report;
