import { routePaths, userTypes } from './constants';

const perimissionProfiles = [
  {
    type: userTypes.MASTER_USER,
    defaultRedirectUrl: routePaths.FILE_LIST_PAGE,
    permittedRoutes: [routePaths.FILE_REVIEW_PAGE, routePaths.FILE_LIST_PAGE, routePaths.PARTNERS],
    customActions: null,
  },
];

export { perimissionProfiles };
