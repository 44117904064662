import { withAuthenticationRequired } from '@auth0/auth0-react';
import { MainLayout } from 'components/layouts';
import { TextInput } from 'flowbite-react';
import 'global.css';
import { SearchIcon } from 'components/assets';
import { useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import debouce from 'lodash.debounce';
import { ChevronIcon } from '../components/assets';
import { useNavigate } from 'react-router-dom';
import PartnerListPageContent from 'components/pages/Partner/PartnerListPageContent';

const Partners = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [searchQuery, setSearchQuery] = useState('');
  const handleChange = e => {
    setSearchQuery(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleClickBack = () => {
    navigate('/');
  };

  return (
    <MainLayout hasNavbar={false}>
      <div className="container mx-auto pt-8">
        <div className="flex pb-10">
          <div
            className="flex content-between w-2/ cursor-pointer"
            data-testid="test-back-button"
            onClick={handleClickBack}
          >
            <div className="col-span-1 flex justify-start items-center">
              <div className="rotate-90">
                <ChevronIcon />
              </div>
              <div className="pl-2">Back to Files</div>
            </div>
          </div>
          <div className="flex-1 flex justify-center">
            <h1>Partners</h1>
          </div>
          <div className="w-2/12">
            <div className="col-span-1 flex justify-end items-center ">{user.email}</div>
          </div>
        </div>
        <div className="sm:w-full md:w-1/2 lg:w-1/3 mb-10">
          <TextInput
            data-testid="test-search-input"
            icon={SearchIcon}
            id="base"
            type="text"
            sizing="md"
            placeholder="Find Partner"
            onChange={debouncedResults}
            autoFocus
          />
        </div>
        <PartnerListPageContent searchQuery={searchQuery} />
      </div>
    </MainLayout>
  );
};
export default withAuthenticationRequired(Partners);
