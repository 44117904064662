import { saveToReware } from 'Api/saveToReware';
import { useQuery } from 'react-query';

export const useSaveToReware = (
  fileId,
  userToken,
  paramsReware,
  enableFetching,
  enableSaveReware,
  handleCloseModal,
  navigate,
) => {
  return useQuery(['SaveToReware', fileId, userToken, paramsReware], saveToReware, {
    staleTime: Infinity,
    enabled: enableFetching && enableSaveReware,
    onSuccess: () => {
      handleCloseModal();
      navigate('/');
    },
  });
};
