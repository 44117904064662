import { acquireLock } from 'Api/document';
import { useMutation, useQueryClient } from 'react-query';

export const useAcquireLockDocument = (setEnableVerify, setEnableRenderInput) => {
  const queryClient = useQueryClient();

  const { mutate: acquireLockDocument, isLoading } = useMutation(acquireLock, {
    onSuccess: data => {
      setEnableVerify(true);
      if (data === undefined) {
        setEnableRenderInput(false);
      }

      queryClient.invalidateQueries('GetFileReview');
      queryClient.invalidateQueries('ListFiles');
    },
  });

  return { acquireLockDocument, isLoading };
};
