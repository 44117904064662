export const type = {
  1: {
    sections: {
      assignment_information: {
        label: 'Assignment Information',
        reference: 1,
        fields: {
          1.1: { type: 'string' },
          1.2: { type: 'string' },
          '1.2.1': { type: 'string' },
          1.3: { type: 'string' },
          1.4: { type: 'string' },
          '1.4.1': { type: 'string' },
          1.5: { type: 'string' },
          1.6: { type: 'string' },
          1.7: { type: 'string' },
          '1.7.1': { type: 'string' },
          1.8: { type: 'string' },
          1.9: { type: 'string' },
          '1.10': { type: 'string' },
          1.11: { type: 'string' },
          1.12: { type: 'string' },
          1.13: { type: 'string' },
          1.14: { type: 'string' },
          1.15: { type: 'string' },
          1.16: { type: 'string' },
          1.17: { type: 'string' },
          1.18: { type: 'string' },
        },
      },
      subject_information: {
        label: 'Subject Information',
        reference: 2,
        fields: {
          2.1: { type: 'string' },
          2.2: { type: 'string' },
          2.3: { type: 'string' },
          2.4: {
            type: 'group',
            label: 'Subject currently listed?',
            values: {
              '2.4.1': { type: 'checkbox' },
              '2.4.2': { type: 'checkbox' },
              '2.4.3': { type: 'string' },
            },
          },
          2.5: {
            type: 'group',
            label: 'Form of ownership',
            values: {
              '2.5.1': { type: 'checkbox' },
              '2.5.2': { type: 'checkbox' },
            },
          },
          2.6: {
            type: 'group',
            label: 'Occupant',
            values: {
              '2.6.1': { type: 'checkbox' },
              '2.6.2': { type: 'checkbox' },
              '2.6.3': { type: 'checkbox' },
            },
          },
          2.7: {
            type: 'group',
            label: 'Type',
            values: {
              '2.7.1': { type: 'checkbox' },
              '2.7.2': { type: 'checkbox' },
              '2.7.3': { type: 'checkbox' },
              '2.7.4': { type: 'checkbox' },
              '2.7.5': { type: 'checkbox' },
              '2.7.6': { type: 'checkbox' },
              '2.7.7': { type: 'checkbox' },
              '2.7.8': { type: 'checkbox' },
              '2.7.9': { type: 'checkbox' },
              '2.7.9.1': { type: 'string' },
            },
          },
          2.8: { type: 'string' },
        },
      },
      subject_location: {
        label: 'Subject Location',
        reference: 3,
        fields: {
          3.1: {
            type: 'group',
            label: "Subject's location type",
            values: {
              '3.1.1': { type: 'checkbox' },
              '3.1.2': { type: 'checkbox' },
              '3.1.3': { type: 'checkbox' },
              '3.1.4': { type: 'checkbox' },
              '3.1.5': { type: 'checkbox' },
              '3.1.6': { type: 'checkbox' },
            },
          },
          3.2: { type: 'string' },
          3.3: { type: 'string' },
          3.4: { type: 'string' },
          3.6: {
            type: 'group',
            label: 'Site Characteristics of which you are aware',
            values: {
              '3.6.1': { type: 'checkbox' },
              '3.6.2': { type: 'checkbox' },
              '3.6.3': { type: 'checkbox' },
              '3.6.4': { type: 'checkbox' },
              '3.6.5': { type: 'checkbox' },
              '3.6.6': { type: 'checkbox' },
              '3.6.7': { type: 'checkbox' },
              '3.6.8': { type: 'checkbox' },
              '3.6.9': { type: 'checkbox' },
              '3.6.10': { type: 'checkbox' },
            },
          },
          3.7: {
            type: 'group',
            label: 'Miscellaneous of which you are aware',
            values: {
              '3.7.1': { type: 'checkbox' },
              '3.7.2': { type: 'checkbox' },
              '3.7.3': { type: 'checkbox' },
              '3.7.4': { type: 'checkbox' },
              '3.7.5': { type: 'checkbox' },
              '3.7.6': { type: 'checkbox' },
              '3.7.7': { type: 'checkbox' },
              '3.7.8': { type: 'checkbox' },
              '3.7.9': { type: 'checkbox' },
              '3.7.10': { type: 'checkbox' },
              '3.7.11': { type: 'checkbox' },
              '3.7.12': { type: 'checkbox' },
              '3.7.13': { type: 'checkbox' },
              '3.7.14': { type: 'checkbox' },
              '3.7.15': { type: 'checkbox' },
              '3.7.16': { type: 'checkbox' },
              '3.7.17': { type: 'checkbox' },
              '3.7.18': { type: 'checkbox' },
              '3.7.19': { type: 'string' },
            },
          },
        },
      },
      miscellaneous: {
        label: 'Miscellaneous',
        reference: 4,
        fields: {
          4.1: {
            type: 'group',
            label: 'Items that may affect marketability',
            values: {
              '4.1.1': { type: 'checkbox' },
              '4.1.2': { type: 'checkbox' },
              '4.1.3': { type: 'checkbox' },
              '4.1.4': { type: 'checkbox' },
              '4.1.5': { type: 'checkbox' },
              '4.1.6': { type: 'checkbox' },
              '4.1.7': { type: 'checkbox' },
              '4.1.8': { type: 'checkbox' },
              '4.1.9': { type: 'checkbox' },
              '4.1.10': { type: 'checkbox' },
              '4.1.11': { type: 'checkbox' },
              '4.1.12': { type: 'checkbox' },
              '4.1.13': { type: 'checkbox' },
              '4.1.14': { type: 'checkbox' },
              '4.1.15': { type: 'checkbox' },
              '4.1.16': { type: 'string' },
            },
          },
        },
      },
    },
  },
  2: {
    sections: {
      subject_condition: {
        label: 'Subject Condition',
        reference: 5,

        fields: {
          '5.0': {
            type: 'group',
            label: 'Property Condition',
            values: {
              5.1: {
                type: 'check_combo',
                subvalues: { '5.1.1': { type: 'string' } },
              },
              5.2: {
                type: 'check_combo',
                subvalues: { '5.2.1': { type: 'string' } },
              },
              5.3: {
                type: 'check_combo',
                subvalues: { '5.3.1': { type: 'string' } },
              },
              5.4: {
                type: 'check_combo',
                subvalues: { '5.4.1': { type: 'string' } },
              },
              5.5: {
                type: 'check_combo',
                subvalues: { '5.5.1': { type: 'string' } },
              },
              5.6: {
                type: 'check_combo',
                subvalues: { '5.6.1': { type: 'string' } },
              },
              5.7: {
                type: 'check_combo',
                subvalues: { '5.7.1': { type: 'string' } },
              },
              5.8: {
                type: 'check_combo',
                subvalues: { '5.8.1': { type: 'string' } },
              },
              5.9: {
                type: 'check_combo',
                subvalues: { '5.9.1': { type: 'string' } },
              },
              '5.10': {
                type: 'check_combo',
                subvalues: { '5.10.1': { type: 'string' } },
              },
              5.11: {
                type: 'check_combo',
                subvalues: { '5.11.1': { type: 'string' } },
              },
              5.12: {
                type: 'check_combo',
                subvalues: { '5.12.1': { type: 'string' } },
              },
              5.13: {
                type: 'check_combo',
                subvalues: { '5.13.1': { type: 'string' } },
              },
              5.14: { type: 'string' },
            },
          },
          '5.14.9': {
            type: 'group',
            label: 'Recommended Repairs - Interior',
            values: {
              5.15: {
                type: 'check_combo',
                subvalues: {
                  '5.15.1': { type: 'float' },
                  '5.15.2': { type: 'string' },
                },
              },
              5.16: {
                type: 'check_combo',
                subvalues: {
                  '5.16.1': { type: 'float' },
                  '5.16.2': { type: 'string' },
                },
              },
              5.17: {
                type: 'check_combo',
                subvalues: {
                  '5.17.1': { type: 'float' },
                  '5.17.2': { type: 'string' },
                },
              },
              5.18: {
                type: 'check_combo',
                subvalues: {
                  '5.18.1': { type: 'float' },
                  '5.18.2': { type: 'string' },
                },
              },
              5.19: {
                type: 'check_combo',
                subvalues: {
                  '5.19.1': { type: 'float' },
                  '5.19.2': { type: 'string' },
                },
              },
              '5.20': {
                type: 'check_combo',
                subvalues: {
                  '5.20.1': { type: 'float' },
                  '5.20.2': { type: 'string' },
                },
              },
              5.21: {
                type: 'check_combo',
                subvalues: {
                  '5.21.1': { type: 'float' },
                  '5.21.2': { type: 'string' },
                },
              },
              5.22: {
                type: 'check_combo',
                subvalues: {
                  '5.22.1': { type: 'float' },
                  '5.22.2': { type: 'string' },
                },
              },
              5.23: {
                type: 'check_combo',
                subvalues: {
                  '5.23.1': { type: 'float' },
                  '5.23.2': { type: 'string' },
                },
              },
              5.24: {
                type: 'check_combo',
                subvalues: {
                  '5.24.1': { type: 'float' },
                  '5.24.2': { type: 'string' },
                },
              },
              5.25: {
                type: 'check_combo',
                subvalues: {
                  '5.25.1': { type: 'float' },
                  '5.25.2': { type: 'string' },
                },
              },
              5.26: { type: 'float' },
            },
          },
          '5.26.9': {
            type: 'group',
            label: 'Recommended Repairs - Exterior',
            values: {
              5.27: {
                type: 'check_combo',
                subvalues: {
                  '5.27.1': { type: 'float' },
                  '5.27.2': { type: 'string' },
                },
              },
              5.28: {
                type: 'check_combo',
                subvalues: {
                  '5.28.1': { type: 'float' },
                  '5.28.2': { type: 'string' },
                },
              },
              5.29: {
                type: 'check_combo',
                subvalues: {
                  '5.29.1': { type: 'float' },
                  '5.29.2': { type: 'string' },
                },
              },
              '5.30': {
                type: 'check_combo',
                subvalues: {
                  '5.30.1': { type: 'float' },
                  '5.30.2': { type: 'string' },
                },
              },
              5.31: {
                type: 'check_combo',
                subvalues: {
                  '5.31.1': { type: 'float' },
                  '5.31.2': { type: 'string' },
                },
              },
              5.32: {
                type: 'check_combo',
                subvalues: {
                  '5.32.1': { type: 'float' },
                  '5.32.2': { type: 'string' },
                },
              },
              5.33: {
                type: 'check_combo',
                subvalues: {
                  '5.33.1': { type: 'float' },
                  '5.33.2': { type: 'string' },
                },
              },
              5.34: {
                type: 'check_combo',
                subvalues: {
                  '5.34.1': { type: 'float' },
                  '5.34.2': { type: 'string' },
                },
              },
              5.35: {
                type: 'check_combo',
                subvalues: {
                  '5.35.1': { type: 'float' },
                  '5.35.2': { type: 'string' },
                },
              },
              5.36: {
                type: 'check_combo',
                subvalues: {
                  '5.36.1': { type: 'float' },
                  '5.36.2': { type: 'string' },
                },
              },
              5.37: {
                type: 'check_combo',
                subvalues: {
                  '5.37.1': { type: 'float' },
                  '5.37.2': { type: 'string' },
                },
              },
              5.38: {
                type: 'check_combo',
                subvalues: {
                  '5.38.1': { type: 'float' },
                  '5.38.2': { type: 'string' },
                },
              },
              5.39: {
                type: 'check_combo',
                subvalues: {
                  '5.39.1': { type: 'float' },
                  '5.39.2': { type: 'string' },
                },
              },
              '5.40': {
                type: 'check_combo',
                subvalues: {
                  '5.40.1': { type: 'float' },
                  '5.40.2': { type: 'string' },
                },
              },
              5.41: {
                type: 'check_combo',
                subvalues: {
                  '5.41.1': { type: 'float' },
                  '5.41.2': { type: 'string' },
                },
              },
              5.42: {
                type: 'check_combo',
                subvalues: {
                  '5.42.1': { type: 'float' },
                  '5.42.2': { type: 'string' },
                },
              },
              5.43: { type: 'float' },
            },
          },
        },
      },
      '5.43.9': {
        reference: 6,
        label: 'Inspections / Disclosures',
        fields: {
          6.1: { type: 'string' },
          6.2: { type: 'string' },
          6.3: { type: 'string' },
          6.4: { type: 'string' },
          6.5: { type: 'string' },
        },
      },
    },
  },
  3: {
    sections: {
      financing: {
        reference: 7,
        label: 'Financing',
        fields: {
          7.1: {
            type: 'group',
            label: 'Most probable means of financing',
            values: {
              '7.1.1': { type: 'checkbox' },
              '7.1.2': { type: 'checkbox' },
              '7.1.3': { type: 'checkbox' },
              '7.1.4': { type: 'checkbox' },
              '7.1.5': { type: 'checkbox' },
              '7.1.5.1': { type: 'string' },
            },
          },
          7.2: { type: 'string' },
          7.3: {
            type: 'check_combo',
            subvalues: { '7.3.1': { type: 'string' } },
          },
          7.4: {
            type: 'check_combo',
            subvalues: { '7.4.1': { type: 'string' } },
          },
          7.5: {
            type: 'check_combo',
            subvalues: { '7.5.1': { type: 'string' } },
          },
          7.6: {
            type: 'group',
            label: 'Issues that may affect financing',
            values: {
              '7.6.1': {
                type: 'check_combo',
                subvalues: { '7.6.1.1': { type: 'string' } },
              },

              '7.6.2': { type: 'checkbox' },
            },
          },
          7.7: {
            type: 'group',
            label: 'Ratio of Owners to Investors',
            values: {
              '7.7.1': { type: 'checkbox' },
              '7.7.2': { type: 'float' },
              '7.7.3': { type: 'float' },
            },
          },
          7.8: {
            type: 'group',
            label: 'HOA Fees',
            values: {
              '7.8.1': { type: 'float' },
              '7.8.2': { type: 'checkbox' },
              '7.8.3': { type: 'checkbox' },
              '7.8.4': { type: 'checkbox' },
              '7.8.5': { type: 'checkbox' },
            },
          },
          7.9: {
            type: 'group',
            label: 'Items included in HOA fees',
            values: {
              '7.9.1': { type: 'checkbox' },
              '7.9.2': { type: 'checkbox' },
              '7.9.3': { type: 'checkbox' },
              '7.9.4': { type: 'checkbox' },
              '7.9.5': { type: 'checkbox' },
              '7.9.6': { type: 'checkbox' },
              '7.9.7': { type: 'checkbox' },
              '7.9.8': { type: 'checkbox' },
              '7.9.9': { type: 'checkbox' },
              '7.9.10': { type: 'checkbox' },
              '7.9.11': { type: 'checkbox' },
              '7.9.12': { type: 'checkbox' },
              '7.9.12.1': { type: 'string' },
            },
          },
          '7.10': {
            type: 'group',
            label: 'Special assessments covered by HOA',
            values: {
              '7.10.1': { type: 'checkbox' },
              '7.10.2': { type: 'checkbox' },
            },
          },
          7.11: { type: 'float' },
          7.12: { type: 'string' },
        },
      },
      market_conditions: {
        label: 'Market Conditions',
        reference: 8,
        fields: {
          8.1: { type: 'string' },
          8.2: {
            type: 'group',
            label: 'Price Range',
            values: {
              '8.2.1': { type: 'float' },
              '8.2.2': { type: 'float' },
            },
          },
          '8.3.1': { type: 'int' },
          8.4: {
            type: 'group',
            label: 'Property Values',
            values: {
              '8.4.1': {
                type: 'check_combo',
                subvalues: { '8.4.2': { type: 'float' }, '8.4.3': { type: 'int' } },
              },
              '8.4.4': { type: 'checkbox' },
              '8.4.5': {
                type: 'check_combo',
                subvalues: { '8.4.6': { type: 'float' }, '8.4.7': { type: 'int' } },
              },
            },
          },

          8.5: {
            type: 'group',
            label: 'Absorption Rate',
            values: {
              '8.5.1': { type: 'int' },
              '8.5.2': { type: 'float' },
              '8.5.3': { type: 'float' },
            },
          },
          8.6: {
            type: 'group',
            label: 'Type of competing listings',
            values: {
              '8.6.1': { type: 'int' },
              '8.6.2': { type: 'int' },
              '8.6.3': { type: 'int' },
              '8.6.4': { type: 'int' },
            },
          },
          8.7: { type: 'string' },
          8.8: { type: 'string' },
          8.9: { type: 'string' },
          '8.10': { type: 'string' },
          8.11: { type: 'string' },
          8.12: { type: 'string' },
        },
      },
    },
  },
  4: {
    sections: {
      // reference: 9,
      subject: {
        label: 'Subject',
        reference: 9.1,
        fields: {
          '9.1.1': { type: 'string' },
          '9.1.2': { type: 'string' },
          '9.1.4': { type: 'float' },
          '9.1.5': { type: 'float' },
          '9.1.6': { type: 'string' },
          '9.1.7': { type: 'string' },
          '9.1.8': { type: 'string' },
          '9.1.9': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '9.1.9.1': { type: 'float' },
              '9.1.9.2': { type: 'float' },
              '9.1.9.3': { type: 'int' },
            },
          },
          '9.1.10': { type: 'string' },
          '9.1.11': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '9.1.11.1': { type: 'checkbox' },
              '9.1.11.2': { type: 'checkbox' },
              '9.1.11.3': { type: 'checkbox' },
              '9.1.11.4': { type: 'checkbox' },
              '9.1.11.5': { type: 'checkbox' },
              '9.1.11.6': { type: 'checkbox' },
              '9.1.11.7': { type: 'checkbox' },
              '9.1.11.8': { type: 'checkbox' },
              '9.1.11.9': { type: 'checkbox' },
              '9.1.11.10': { type: 'checkbox' },
              '9.1.11.10.1': { type: 'string' },
            },
          },
          '9.1.12': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '9.1.12.1': { type: 'checkbox' },
              '9.1.12.2': { type: 'checkbox' },
              '9.1.12.3': { type: 'checkbox' },
              '9.1.12.4': { type: 'checkbox' },
              '9.1.12.5': { type: 'checkbox' },
              '9.1.12.6': { type: 'checkbox' },
              '9.1.12.7': { type: 'checkbox' },
              '9.1.12.8': { type: 'checkbox' },
              '9.1.12.9': { type: 'checkbox' },
              '9.1.12.9.1': { type: 'string' },
            },
          },
          '9.1.13': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '9.1.13.1': { type: 'checkbox' },
              '9.1.13.2': {
                type: 'check_combo',
                subvalues: {
                  '9.1.13.3': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.4': { type: 'int' } },
                  },
                  '9.1.13.5': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.6': { type: 'int' } },
                  },
                  '9.1.13.7': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.8': { type: 'int' } },
                  },
                },
              },
              '9.1.13.9': {
                type: 'check_combo',
                subvalues: {
                  '9.1.13.10': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.11': { type: 'int' } },
                  },
                  '9.1.13.12': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.13': { type: 'int' } },
                  },
                  '9.1.13.14': {
                    type: 'check_combo',
                    subvalues: { '9.1.13.15': { type: 'int' } },
                  },
                },
              },
              '9.1.13.16': {
                type: 'check_combo',
                subvalues: { '9.1.16.1': { type: 'string' } },
              },
            },
          },
          '9.1.14': { type: 'string' },
          '9.1.15': { type: 'string' },
          '9.1.16': { type: 'string' },
          '9.1.17': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '9.1.17.1': { type: 'int' },
              '9.1.17.2': { type: 'int' },
              '9.1.17.3': { type: 'int' },
            },
          },
          '9.1.18': {
            type: 'group',
            label: 'Basement',
            values: {
              '9.1.18.1': { type: 'checkbox' },
              '9.1.18.2': { type: 'checkbox' },
              '9.1.18.3': { type: 'checkbox' },
              '9.1.18.4': { type: 'checkbox' },
              '9.1.18.5': { type: 'checkbox' },
              '9.1.18.6': { type: 'checkbox' },
            },
          },
          '9.1.19': { type: 'string' },
          '9.1.20': {
            type: 'group',
            label: 'Attic',
            values: {
              '9.1.20.1': { type: 'checkbox' },
              '9.1.20.2': { type: 'checkbox' },
              '9.1.20.3': { type: 'checkbox' },
              '9.1.20.4': { type: 'checkbox' },
            },
          },
          '9.1.21': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '9.1.21.1': { type: 'checkbox' },
              '9.1.21.2': { type: 'checkbox' },
              '9.1.21.3': { type: 'checkbox' },
            },
          },
          '9.1.22': { type: 'string' },
          '9.1.23': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '9.1.23.1': { type: 'checkbox' },
              '9.1.23.2': { type: 'checkbox' },
              '9.1.23.3': { type: 'checkbox' },
              '9.1.23.4': { type: 'checkbox' },
              '9.1.23.5': { type: 'checkbox' },
              '9.1.23.6': { type: 'checkbox' },
              '9.1.23.7': { type: 'checkbox' },
              '9.1.23.7.1': { type: 'string' },
            },
          },
          '9.1.24': { type: 'string' },
          '9.1.25': { type: 'string' },
          '9.1.26': { type: 'string' },
          '9.1.27': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '9.1.27.1': { type: 'checkbox' },
              '9.1.27.2': { type: 'checkbox' },
              '9.1.27.3': { type: 'checkbox' },
              '9.1.27.4': { type: 'checkbox' },
            },
          },
          '9.1.28': {
            type: 'group',
            label: 'Waste Disposal',
            values: {
              '9.1.28.1': { type: 'checkbox' },
              '9.1.28.2': { type: 'checkbox' },
              '9.1.28.3': { type: 'checkbox' },
              '9.1.28.4': { type: 'checkbox' },
              '9.1.28.4.1': { type: 'string' },
            },
          },
          '9.1.29': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '9.1.29.1': { type: 'checkbox' },
              '9.1.29.2': { type: 'checkbox' },
              '9.1.29.3': { type: 'checkbox' },
              '9.1.29.4': { type: 'checkbox' },
              '9.1.29.5': { type: 'checkbox' },
              '9.1.29.5.1': { type: 'string' },
            },
          },
          '9.1.30': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '9.1.30.1': { type: 'checkbox' },
              '9.1.30.2': { type: 'checkbox' },
              '9.1.30.3': { type: 'checkbox' },
              '9.1.30.4': { type: 'checkbox' },
              '9.1.30.5': { type: 'checkbox' },
              '9.1.30.6': { type: 'checkbox' },
              '9.1.30.7': { type: 'checkbox' },
              '9.1.30.8': { type: 'checkbox' },
              '9.1.30.9': { type: 'checkbox' },
              '9.1.30.10': { type: 'checkbox' },
              '9.1.30.11': { type: 'checkbox' },
              '9.1.30.12': { type: 'checkbox' },
              '9.1.30.13': { type: 'checkbox' },
              '9.1.30.13.1': { type: 'string' },
            },
          },
        },
      },
      competing_listing_1: {
        label: 'Competing Listing #1',
        reference: 9.2,
        fields: {
          '9.2.1': { type: 'string' },
          '9.2.2': { type: 'string' },
          '9.2.3': { type: 'string' },
          '9.2.4': { type: 'float' },
          '9.2.5': { type: 'float' },
          '9.2.6': { type: 'string' },
          '9.2.7': { type: 'string' },
          '9.2.8': { type: 'string' },
          '9.2.9': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '9.2.9.1': { type: 'float' },
              '9.2.9.2': { type: 'float' },
              '9.2.9.3': { type: 'int' },
            },
          },
          '9.2.10': { type: 'string' },
          '9.2.11': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '9.2.11.1': { type: 'checkbox' },
              '9.2.11.2': { type: 'checkbox' },
              '9.2.11.3': { type: 'checkbox' },
              '9.2.11.4': { type: 'checkbox' },
              '9.2.11.5': { type: 'checkbox' },
              '9.2.11.6': { type: 'checkbox' },
              '9.2.11.7': { type: 'checkbox' },
              '9.2.11.8': { type: 'checkbox' },
              '9.2.11.9': { type: 'checkbox' },
              '9.2.11.10': { type: 'checkbox' },
              '9.2.11.10.1': { type: 'string' },
            },
          },
          '9.2.12': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '9.2.12.1': { type: 'checkbox' },
              '9.2.12.2': { type: 'checkbox' },
              '9.2.12.3': { type: 'checkbox' },
              '9.2.12.4': { type: 'checkbox' },
              '9.2.12.5': { type: 'checkbox' },
              '9.2.12.6': { type: 'checkbox' },
              '9.2.12.7': { type: 'checkbox' },
              '9.2.12.8': { type: 'checkbox' },
              '9.2.12.9': { type: 'checkbox' },
              '9.2.12.9.1': { type: 'string' },
            },
          },
          '9.2.13': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '9.2.13.1': { type: 'checkbox' },
              '9.2.13.2': {
                type: 'check_combo',
                subvalues: {
                  '9.2.13.3': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.4': { type: 'int' } },
                  },
                  '9.2.13.5': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.6': { type: 'int' } },
                  },
                  '9.2.13.7': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.8': { type: 'int' } },
                  },
                },
              },
              '9.2.13.9': {
                type: 'check_combo',
                subvalues: {
                  '9.2.13.10': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.11': { type: 'int' } },
                  },
                  '9.2.13.12': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.13': { type: 'int' } },
                  },
                  '9.2.13.14': {
                    type: 'check_combo',
                    subvalues: { '9.2.13.15': { type: 'int' } },
                  },
                },
              },
              '9.2.13.16': {
                type: 'check_combo',
                subvalues: { '9.2.13.16.1': { type: 'string' } },
              },
            },
          },
          '9.2.14': { type: 'string' },
          '9.2.15': { type: 'string' },
          '9.2.16': { type: 'string' },
          '9.2.17': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '9.2.17.1': { type: 'int' },
              '9.2.17.2': { type: 'int' },
              '9.2.17.3': { type: 'int' },
            },
          },
          '9.2.18': {
            type: 'group',
            label: 'Basement',
            values: {
              '9.2.18.1': { type: 'checkbox' },
              '9.2.18.2': { type: 'checkbox' },
              '9.2.18.3': { type: 'checkbox' },
              '9.2.18.4': { type: 'checkbox' },
              '9.2.18.5': { type: 'checkbox' },
              '9.2.18.6': { type: 'checkbox' },
            },
          },
          '9.2.19': { type: 'string' },
          '9.2.20': {
            type: 'group',
            label: 'Attic',
            values: {
              '9.2.20.1': { type: 'checkbox' },
              '9.2.20.2': { type: 'checkbox' },
              '9.2.20.3': { type: 'checkbox' },
              '9.2.20.4': { type: 'checkbox' },
            },
          },
          '9.2.21': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '9.2.21.1': { type: 'checkbox' },
              '9.2.21.2': { type: 'checkbox' },
              '9.2.21.3': { type: 'checkbox' },
            },
          },
          '9.2.22': { type: 'string' },
          '9.2.23': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '9.2.23.1': { type: 'checkbox' },
              '9.2.23.2': { type: 'checkbox' },
              '9.2.23.3': { type: 'checkbox' },
              '9.2.23.4': { type: 'checkbox' },
              '9.2.23.5': { type: 'checkbox' },
              '9.2.23.6': { type: 'checkbox' },
              '9.2.23.7': { type: 'checkbox' },
              '9.2.23.7.1': { type: 'string' },
            },
          },
          '9.2.24': { type: 'string' },
          '9.2.25': { type: 'string' },
          '9.2.26': { type: 'string' },
          '9.2.27': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '9.2.27.1': { type: 'checkbox' },
              '9.2.27.2': { type: 'checkbox' },
              '9.2.27.3': { type: 'checkbox' },
              '9.2.27.4': { type: 'checkbox' },
            },
          },
          '9.2.28': {
            type: 'group',
            label: 'Waste Disposal',
            values: {
              '9.2.28.1': { type: 'checkbox' },
              '9.2.28.2': { type: 'checkbox' },
              '9.2.28.3': { type: 'checkbox' },
              '9.2.28.4': { type: 'checkbox' },
              '9.2.28.4.1': { type: 'string' },
            },
          },
          '9.2.29': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '9.2.29.1': { type: 'checkbox' },
              '9.2.29.2': { type: 'checkbox' },
              '9.2.29.3': { type: 'checkbox' },
              '9.2.29.4': { type: 'checkbox' },
              '9.2.29.5': { type: 'checkbox' },
              '9.2.29.5.1': { type: 'string' },
            },
          },
          '9.2.30': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '9.2.30.1': { type: 'checkbox' },
              '9.2.30.2': { type: 'checkbox' },
              '9.2.30.3': { type: 'checkbox' },
              '9.2.30.4': { type: 'checkbox' },
              '9.2.30.5': { type: 'checkbox' },
              '9.2.30.6': { type: 'checkbox' },
              '9.2.30.7': { type: 'checkbox' },
              '9.2.30.8': { type: 'checkbox' },
              '9.2.30.9': { type: 'checkbox' },
              '9.2.30.10': { type: 'checkbox' },
              '9.2.30.11': { type: 'checkbox' },
              '9.2.30.12': { type: 'checkbox' },
              '9.2.30.13': { type: 'checkbox' },
              '9.2.30.13.1': { type: 'string' },
            },
          },
          '9.2.31': {
            type: 'group',
            label: 'Location',
            values: {
              '9.2.31.1': { type: 'checkbox' },
              '9.2.31.2': { type: 'checkbox' },
              '9.2.31.3': { type: 'checkbox' },
            },
          },
          '9.2.32': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '9.2.32.1': { type: 'checkbox' },
              '9.2.32.2': { type: 'checkbox' },
              '9.2.32.3': { type: 'checkbox' },
            },
          },
          '9.2.33': {
            type: 'group',
            label: 'View',
            values: {
              '9.2.33.1': { type: 'checkbox' },
              '9.2.33.2': { type: 'checkbox' },
              '9.2.33.3': { type: 'checkbox' },
            },
          },
          '9.2.34': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '9.2.34.1': { type: 'checkbox' },
              '9.2.34.2': { type: 'checkbox' },
              '9.2.34.3': { type: 'checkbox' },
            },
          },
          '9.2.35': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '9.2.35.1': { type: 'checkbox' },
              '9.2.35.2': { type: 'checkbox' },
              '9.2.35.3': { type: 'checkbox' },
            },
          },
          '9.2.36': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '9.2.36.1': { type: 'checkbox' },
              '9.2.36.2': { type: 'checkbox' },
              '9.2.36.3': { type: 'checkbox' },
            },
          },
          '9.2.37': {
            type: 'group',
            label: 'Overall Rating Compared to Subject',
            values: {
              '9.2.37.1': { type: 'checkbox' },
              '9.2.37.2': { type: 'checkbox' },
              '9.2.37.3': { type: 'checkbox' },
            },
          },
          '9.2.38': {
            type: 'group',
            label: 'Listing #1',
            values: {
              '9.2.38.1': { type: 'string' },
              '9.2.38.2': { type: 'string' },
            },
          },
        },
      },
      competing_listing_2: {
        label: 'Competing Listing #2',
        reference: 9.3,
        fields: {
          '9.3.1': { type: 'string' },
          '9.3.2': { type: 'string' },
          '9.3.3': { type: 'string' },
          '9.3.4': { type: 'float' },
          '9.3.5': { type: 'float' },
          '9.3.6': { type: 'string' },
          '9.3.7': { type: 'string' },
          '9.3.8': { type: 'string' },
          '9.3.9': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '9.3.9.1': { type: 'float' },
              '9.3.9.2': { type: 'float' },
              '9.3.9.3': { type: 'int' },
            },
          },
          '9.3.10': { type: 'string' },
          '9.3.11': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '9.3.11.1': { type: 'checkbox' },
              '9.3.11.2': { type: 'checkbox' },
              '9.3.11.3': { type: 'checkbox' },
              '9.3.11.4': { type: 'checkbox' },
              '9.3.11.5': { type: 'checkbox' },
              '9.3.11.6': { type: 'checkbox' },
              '9.3.11.7': { type: 'checkbox' },
              '9.3.11.8': { type: 'checkbox' },
              '9.3.11.9': { type: 'checkbox' },
              '9.3.11.10': { type: 'checkbox' },
              '9.3.11.10.1': { type: 'string' },
            },
          },
          '9.3.12': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '9.3.12.1': { type: 'checkbox' },
              '9.3.12.2': { type: 'checkbox' },
              '9.3.12.3': { type: 'checkbox' },
              '9.3.12.4': { type: 'checkbox' },
              '9.3.12.5': { type: 'checkbox' },
              '9.3.12.6': { type: 'checkbox' },
              '9.3.12.7': { type: 'checkbox' },
              '9.3.12.8': { type: 'checkbox' },
              '9.3.12.9': { type: 'checkbox' },
              '9.3.12.9.1': { type: 'string' },
            },
          },
          '9.3.13': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '9.3.13.1': { type: 'checkbox' },
              '9.3.13.2': {
                type: 'check_combo',
                subvalues: {
                  '9.3.13.3': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.4': { type: 'int' } },
                  },
                  '9.3.13.5': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.6': { type: 'int' } },
                  },
                  '9.3.13.7': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.8': { type: 'int' } },
                  },
                },
              },
              '9.3.13.9': {
                type: 'check_combo',
                subvalues: {
                  '9.3.13.10': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.11': { type: 'int' } },
                  },
                  '9.3.13.12': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.13': { type: 'int' } },
                  },
                  '9.3.13.14': {
                    type: 'check_combo',
                    subvalues: { '9.3.13.15': { type: 'int' } },
                  },
                },
              },
              '9.3.13.16': {
                type: 'check_combo',
                subvalues: { '9.3.13.16.1': { type: 'string' } },
              },
            },
          },
          '9.3.14': { type: 'string' },
          '9.3.15': { type: 'string' },
          '9.3.16': { type: 'string' },
          '9.3.17': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '9.3.17.1': { type: 'int' },
              '9.3.17.2': { type: 'int' },
              '9.3.17.3': { type: 'int' },
            },
          },
          '9.3.18': {
            type: 'group',
            label: 'Basement',
            values: {
              '9.3.18.1': { type: 'checkbox' },
              '9.3.18.2': { type: 'checkbox' },
              '9.3.18.3': { type: 'checkbox' },
              '9.3.18.4': { type: 'checkbox' },
              '9.3.18.5': { type: 'checkbox' },
              '9.3.18.6': { type: 'checkbox' },
            },
          },
          '9.3.19': { type: 'string' },
          '9.3.20': {
            type: 'group',
            label: 'Attic',
            values: {
              '9.3.20.1': { type: 'checkbox' },
              '9.3.20.2': { type: 'checkbox' },
              '9.3.20.3': { type: 'checkbox' },
              '9.3.20.4': { type: 'checkbox' },
            },
          },
          '9.3.21': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '9.3.21.1': { type: 'checkbox' },
              '9.3.21.2': { type: 'checkbox' },
              '9.3.21.3': { type: 'checkbox' },
            },
          },
          '9.3.22': { type: 'string' },
          '9.3.23': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '9.3.23.1': { type: 'checkbox' },
              '9.3.23.2': { type: 'checkbox' },
              '9.3.23.3': { type: 'checkbox' },
              '9.3.23.4': { type: 'checkbox' },
              '9.3.23.5': { type: 'checkbox' },
              '9.3.23.6': { type: 'checkbox' },
              '9.3.23.7': { type: 'checkbox' },
              '9.3.23.7.1': { type: 'string' },
            },
          },
          '9.3.24': { type: 'string' },
          '9.3.25': { type: 'string' },
          '9.3.26': { type: 'string' },
          '9.3.27': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '9.3.27.1': { type: 'checkbox' },
              '9.3.27.2': { type: 'checkbox' },
              '9.3.27.3': { type: 'checkbox' },
              '9.3.27.4': { type: 'checkbox' },
            },
          },
          '9.3.28': {
            type: 'group',
            label: 'Waste Disposal',
            values: {
              '9.3.28.1': { type: 'checkbox' },
              '9.3.28.2': { type: 'checkbox' },
              '9.3.28.3': { type: 'checkbox' },
              '9.3.28.4': { type: 'checkbox' },
              '9.3.28.4.1': { type: 'string' },
            },
          },
          '9.3.29': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '9.3.29.1': { type: 'checkbox' },
              '9.3.29.2': { type: 'checkbox' },
              '9.3.29.3': { type: 'checkbox' },
              '9.3.29.4': { type: 'checkbox' },
              '9.3.29.5': { type: 'checkbox' },
              '9.3.29.5.1': { type: 'string' },
            },
          },
          '9.3.30': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '9.3.30.1': { type: 'checkbox' },
              '9.3.30.2': { type: 'checkbox' },
              '9.3.30.3': { type: 'checkbox' },
              '9.3.30.4': { type: 'checkbox' },
              '9.3.30.5': { type: 'checkbox' },
              '9.3.30.6': { type: 'checkbox' },
              '9.3.30.7': { type: 'checkbox' },
              '9.3.30.8': { type: 'checkbox' },
              '9.3.30.9': { type: 'checkbox' },
              '9.3.30.10': { type: 'checkbox' },
              '9.3.30.11': { type: 'checkbox' },
              '9.3.30.12': { type: 'checkbox' },
              '9.3.30.13': { type: 'checkbox' },
              '9.3.30.13.1': { type: 'string' },
            },
          },
          '9.3.31': {
            type: 'group',
            label: 'Location',
            values: {
              '9.3.31.1': { type: 'checkbox' },
              '9.3.31.2': { type: 'checkbox' },
              '9.3.31.3': { type: 'checkbox' },
            },
          },
          '9.3.32': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '9.3.32.1': { type: 'checkbox' },
              '9.3.32.2': { type: 'checkbox' },
              '9.3.32.3': { type: 'checkbox' },
            },
          },
          '9.3.33': {
            type: 'group',
            label: 'View',
            values: {
              '9.3.33.1': { type: 'checkbox' },
              '9.3.33.2': { type: 'checkbox' },
              '9.3.33.3': { type: 'checkbox' },
            },
          },
          '9.3.34': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '9.3.34.1': { type: 'checkbox' },
              '9.3.34.2': { type: 'checkbox' },
              '9.3.34.3': { type: 'checkbox' },
            },
          },
          '9.3.35': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '9.3.35.1': { type: 'checkbox' },
              '9.3.35.2': { type: 'checkbox' },
              '9.3.35.3': { type: 'checkbox' },
            },
          },
          '9.3.36': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '9.3.36.1': { type: 'checkbox' },
              '9.3.36.2': { type: 'checkbox' },
              '9.3.36.3': { type: 'checkbox' },
            },
          },
          '9.3.37': {
            type: 'group',
            label: 'Overall Rating Compared to Subject',
            values: {
              '9.3.37.1': { type: 'checkbox' },
              '9.3.37.2': { type: 'checkbox' },
              '9.3.37.3': { type: 'checkbox' },
            },
          },
          '9.3.38': {
            type: 'group',
            label: 'Listing #2',
            values: {
              '9.3.38.1': { type: 'string' },
              '9.3.38.2': { type: 'string' },
            },
          },
        },
      },
      competing_listing_3: {
        label: 'Competing Listing #3',
        reference: 9.4,
        fields: {
          '9.4.1': { type: 'string' },
          '9.4.2': { type: 'string' },
          '9.4.3': { type: 'string' },
          '9.4.4': { type: 'float' },
          '9.4.5': { type: 'float' },
          '9.4.6': { type: 'string' },
          '9.4.7': { type: 'string' },
          '9.4.8': { type: 'string' },
          '9.4.9': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '9.4.9.1': { type: 'float' },
              '9.4.9.2': { type: 'float' },
              '9.4.9.3': { type: 'int' },
            },
          },
          '9.4.10': { type: 'string' },
          '9.4.11': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '9.4.11.1': { type: 'checkbox' },
              '9.4.11.2': { type: 'checkbox' },
              '9.4.11.3': { type: 'checkbox' },
              '9.4.11.4': { type: 'checkbox' },
              '9.4.11.5': { type: 'checkbox' },
              '9.4.11.6': { type: 'checkbox' },
              '9.4.11.7': { type: 'checkbox' },
              '9.4.11.8': { type: 'checkbox' },
              '9.4.11.9': { type: 'checkbox' },
              '9.4.11.10': { type: 'checkbox' },
              '9.4.11.10.1': { type: 'string' },
            },
          },
          '9.4.12': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '9.4.12.1': { type: 'checkbox' },
              '9.4.12.2': { type: 'checkbox' },
              '9.4.12.3': { type: 'checkbox' },
              '9.4.12.4': { type: 'checkbox' },
              '9.4.12.5': { type: 'checkbox' },
              '9.4.12.6': { type: 'checkbox' },
              '9.4.12.7': { type: 'checkbox' },
              '9.4.12.8': { type: 'checkbox' },
              '9.4.12.9': { type: 'checkbox' },
              '9.4.12.9.1': { type: 'string' },
            },
          },
          '9.4.13': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '9.4.13.1': { type: 'checkbox' },
              '9.4.13.2': {
                type: 'check_combo',
                subvalues: {
                  '9.4.13.3': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.4': { type: 'int' } },
                  },
                  '9.4.13.5': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.6': { type: 'int' } },
                  },
                  '9.4.13.7': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.8': { type: 'int' } },
                  },
                },
              },
              '9.4.13.9': {
                type: 'check_combo',
                subvalues: {
                  '9.4.13.10': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.11': { type: 'int' } },
                  },
                  '9.4.13.12': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.13': { type: 'int' } },
                  },
                  '9.4.13.14': {
                    type: 'check_combo',
                    subvalues: { '9.4.13.15': { type: 'int' } },
                  },
                },
              },
              '9.4.13.16': {
                type: 'check_combo',
                subvalues: { '9.4.13.16.1': { type: 'string' } },
              },
            },
          },
          '9.4.14': { type: 'string' },
          '9.4.15': { type: 'string' },
          '9.4.16': { type: 'string' },
          '9.4.17': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '9.4.17.1': { type: 'int' },
              '9.4.17.2': { type: 'int' },
              '9.4.17.3': { type: 'int' },
            },
          },
          '9.4.18': {
            type: 'group',
            label: 'Basement',
            values: {
              '9.4.18.1': { type: 'checkbox' },
              '9.4.18.2': { type: 'checkbox' },
              '9.4.18.3': { type: 'checkbox' },
              '9.4.18.4': { type: 'checkbox' },
              '9.4.18.5': { type: 'checkbox' },
              '9.4.18.6': { type: 'checkbox' },
            },
          },
          '9.4.19': { type: 'string' },
          '9.4.20': {
            type: 'group',
            label: 'Attic',
            values: {
              '9.4.20.1': { type: 'checkbox' },
              '9.4.20.2': { type: 'checkbox' },
              '9.4.20.3': { type: 'checkbox' },
              '9.4.20.4': { type: 'checkbox' },
            },
          },
          '9.4.21': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '9.4.21.1': { type: 'checkbox' },
              '9.4.21.2': { type: 'checkbox' },
              '9.4.21.3': { type: 'checkbox' },
            },
          },
          '9.4.22': { type: 'string' },
          '9.4.23': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '9.4.23.1': { type: 'checkbox' },
              '9.4.23.2': { type: 'checkbox' },
              '9.4.23.3': { type: 'checkbox' },
              '9.4.23.4': { type: 'checkbox' },
              '9.4.23.5': { type: 'checkbox' },
              '9.4.23.6': { type: 'checkbox' },
              '9.4.23.7': { type: 'checkbox' },
              '9.4.23.7.1': { type: 'string' },
            },
          },
          '9.4.24': { type: 'string' },
          '9.4.25': { type: 'string' },
          '9.4.26': { type: 'string' },
          '9.4.27': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '9.4.27.1': { type: 'checkbox' },
              '9.4.27.2': { type: 'checkbox' },
              '9.4.27.3': { type: 'checkbox' },
              '9.4.27.4': { type: 'checkbox' },
            },
          },
          '9.4.28': {
            type: 'group',
            label: 'Waste Disposal',
            values: {
              '9.4.28.1': { type: 'checkbox' },
              '9.4.28.2': { type: 'checkbox' },
              '9.4.28.3': { type: 'checkbox' },
              '9.4.28.4': { type: 'checkbox' },
              '9.4.28.4.1': { type: 'string' },
            },
          },
          '9.4.29': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '9.4.29.1': { type: 'checkbox' },
              '9.4.29.2': { type: 'checkbox' },
              '9.4.29.3': { type: 'checkbox' },
              '9.4.29.4': { type: 'checkbox' },
              '9.4.29.5': { type: 'checkbox' },
              '9.4.29.5.1': { type: 'string' },
            },
          },
          '9.4.30': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '9.4.30.1': { type: 'checkbox' },
              '9.4.30.2': { type: 'checkbox' },
              '9.4.30.3': { type: 'checkbox' },
              '9.4.30.4': { type: 'checkbox' },
              '9.4.30.5': { type: 'checkbox' },
              '9.4.30.6': { type: 'checkbox' },
              '9.4.30.7': { type: 'checkbox' },
              '9.4.30.8': { type: 'checkbox' },
              '9.4.30.9': { type: 'checkbox' },
              '9.4.30.10': { type: 'checkbox' },
              '9.4.30.11': { type: 'checkbox' },
              '9.4.30.12': { type: 'checkbox' },
              '9.4.30.13': { type: 'checkbox' },
              '9.4.30.13.1': { type: 'string' },
            },
          },
          '9.4.31': {
            type: 'group',
            label: 'Location',
            values: {
              '9.4.31.1': { type: 'checkbox' },
              '9.4.31.2': { type: 'checkbox' },
              '9.4.31.3': { type: 'checkbox' },
            },
          },
          '9.4.32': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '9.4.32.1': { type: 'checkbox' },
              '9.4.32.2': { type: 'checkbox' },
              '9.4.32.3': { type: 'checkbox' },
            },
          },
          '9.4.33': {
            type: 'group',
            label: 'View',
            values: {
              '9.4.33.1': { type: 'checkbox' },
              '9.4.33.2': { type: 'checkbox' },
              '9.4.33.3': { type: 'checkbox' },
            },
          },
          '9.4.34': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '9.4.34.1': { type: 'checkbox' },
              '9.4.34.2': { type: 'checkbox' },
              '9.4.34.3': { type: 'checkbox' },
            },
          },
          '9.4.35': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '9.4.35.1': { type: 'checkbox' },
              '9.4.35.2': { type: 'checkbox' },
              '9.4.35.3': { type: 'checkbox' },
            },
          },
          '9.4.36': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '9.4.36.1': { type: 'checkbox' },
              '9.4.36.2': { type: 'checkbox' },
              '9.4.36.3': { type: 'checkbox' },
            },
          },
          '9.4.37': {
            type: 'group',
            label: 'Overall Rating Compared to Subject',
            values: {
              '9.4.37.1': { type: 'checkbox' },
              '9.4.37.2': { type: 'checkbox' },
              '9.4.37.3': { type: 'checkbox' },
            },
          },
          '9.4.38': {
            type: 'group',
            label: 'Listing #3',
            values: {
              '9.4.38.1': { type: 'string' },
              '9.4.38.2': { type: 'string' },
            },
          },
        },
      },
    },
  },
  5: {
    sections: {
      // reference: 10,
      subject: {
        label: 'Subject',
        reference: 10.1,
        fields: {
          '10.1.1': { type: 'string' },
          '10.1.2': { type: 'string' },
          '10.1.4': { type: 'float' },
          '10.1.7': { type: 'string' },
          '10.1.10': { type: 'string' },
          '10.1.11': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '10.1.11.1': { type: 'float' },
              '10.1.11.2': { type: 'float' },
              '10.1.11.3': { type: 'int' },
            },
          },
          '10.1.12': { type: 'string' },
          '10.1.13': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '10.1.13.1': { type: 'checkbox' },
              '10.1.13.2': { type: 'checkbox' },
              '10.1.13.3': { type: 'checkbox' },
              '10.1.13.4': { type: 'checkbox' },
              '10.1.13.5': { type: 'checkbox' },
              '10.1.13.6': { type: 'checkbox' },
              '10.1.13.7': { type: 'checkbox' },
              '10.1.13.8': { type: 'checkbox' },
              '10.1.13.9': { type: 'checkbox' },
              '10.1.13.10': { type: 'checkbox' },
              '10.1.13.10.1': { type: 'string' },
            },
          },
          '10.1.14': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '10.1.14.1': { type: 'checkbox' },
              '10.1.14.2': { type: 'checkbox' },
              '10.1.14.3': { type: 'checkbox' },
              '10.1.14.4': { type: 'checkbox' },
              '10.1.14.5': { type: 'checkbox' },
              '10.1.14.6': { type: 'checkbox' },
              '10.1.14.7': { type: 'checkbox' },
              '10.1.14.8': { type: 'checkbox' },
              '10.1.14.9': { type: 'checkbox' },
              '10.1.14.9.1': { type: 'string' },
            },
          },
          '10.1.15': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '10.1.15.1': { type: 'checkbox' },
              '10.1.15.2': {
                type: 'check_combo',
                subvalues: {
                  '10.1.15.3': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.4': { type: 'int' } },
                  },
                  '10.1.15.5': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.6': { type: 'int' } },
                  },
                  '10.1.15.7': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.8': { type: 'int' } },
                  },
                },
              },
              '10.1.15.9': {
                type: 'check_combo',
                subvalues: {
                  '10.1.15.10': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.11': { type: 'int' } },
                  },
                  '10.1.15.12': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.13': { type: 'int' } },
                  },
                  '10.1.15.14': {
                    type: 'check_combo',
                    subvalues: { '10.1.15.15': { type: 'int' } },
                  },
                },
              },
              '10.1.15.16': {
                type: 'check_combo',
                subvalues: { '10.1.15.16.1': { type: 'string' } },
              },
            },
          },
          '10.1.16': { type: 'string' },
          '10.1.17': { type: 'string' },
          '10.1.18': { type: 'string' },
          '10.1.19': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '10.1.19.1': { type: 'int' },
              '10.1.19.2': { type: 'int' },
              '10.1.19.3': { type: 'int' },
            },
          },
          '10.1.20': {
            type: 'group',
            label: 'Basement',
            values: {
              '10.1.20.1': { type: 'checkbox' },
              '10.1.20.2': { type: 'checkbox' },
              '10.1.20.3': { type: 'checkbox' },
              '10.1.20.4': { type: 'checkbox' },
              '10.1.20.5': { type: 'checkbox' },
              '10.1.20.6': { type: 'checkbox' },
            },
          },
          '10.1.21': { type: 'string' },
          '10.1.22': {
            type: 'group',
            label: 'Attic',
            values: {
              '10.1.22.1': { type: 'checkbox' },
              '10.1.22.2': { type: 'checkbox' },
              '10.1.22.3': { type: 'checkbox' },
              '10.1.22.4': { type: 'checkbox' },
            },
          },
          '10.1.23': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '10.1.23.1': { type: 'checkbox' },
              '10.1.23.2': { type: 'checkbox' },
              '10.1.23.3': { type: 'checkbox' },
            },
          },
          '10.1.24': { type: 'string' },
          '10.1.25': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '10.1.25.1': { type: 'checkbox' },
              '10.1.25.2': { type: 'checkbox' },
              '10.1.25.3': { type: 'checkbox' },
              '10.1.25.4': { type: 'checkbox' },
              '10.1.25.5': { type: 'checkbox' },
              '10.1.25.6': { type: 'checkbox' },
              '10.1.25.7': { type: 'checkbox' },
              '10.1.25.7.1': { type: 'string' },
            },
          },
          '10.1.26': { type: 'string' },
          '10.1.27': { type: 'string' },
          '10.1.28': { type: 'string' },
          '10.1.29': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '10.1.29.1': { type: 'checkbox' },
              '10.1.29.2': { type: 'checkbox' },
              '10.1.29.3': { type: 'checkbox' },
              '10.1.29.4': { type: 'checkbox' },
            },
          },
          '10.1.30': {
            type: 'group',
            label: 'Waste Disposal',
            values: {
              '10.1.30.1': { type: 'checkbox' },
              '10.1.30.2': { type: 'checkbox' },
              '10.1.30.3': { type: 'checkbox' },
              '10.1.30.4': { type: 'checkbox' },
              '10.1.30.4.1': { type: 'string' },
            },
          },
          '10.1.31': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '10.1.31.1': { type: 'checkbox' },
              '10.1.31.2': { type: 'checkbox' },
              '10.1.31.3': { type: 'checkbox' },
              '10.1.31.4': { type: 'checkbox' },
              '10.1.31.5': { type: 'checkbox' },
              '10.1.31.5.1': { type: 'string' },
            },
          },
          '10.1.32': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '10.1.32.1': { type: 'checkbox' },
              '10.1.32.2': { type: 'checkbox' },
              '10.1.32.3': { type: 'checkbox' },
              '10.1.32.4': { type: 'checkbox' },
              '10.1.32.5': { type: 'checkbox' },
              '10.1.32.6': { type: 'checkbox' },
              '10.1.32.7': { type: 'checkbox' },
              '10.1.32.8': { type: 'checkbox' },
              '10.1.32.9': { type: 'checkbox' },
              '10.1.32.10': { type: 'checkbox' },
              '10.1.32.11': { type: 'checkbox' },
              '10.1.32.12': { type: 'checkbox' },
              '10.1.32.13': { type: 'checkbox' },
              '10.1.32.13.1': { type: 'string' },
            },
          },
        },
      },
      comparable_listing_1: {
        label: 'Comparable Listing 1',
        reference: 10.2,
        fields: {
          '10.2.1': { type: 'string' },
          '10.2.2': { type: 'string' },
          '10.2.3': { type: 'string' },
          '10.2.4': { type: 'float' },
          '10.2.5': { type: 'float' },
          '10.2.6': { type: 'float' },
          '10.2.7': { type: 'string' },
          '10.2.8': { type: 'string' },
          '10.2.9': { type: 'string' },
          '10.2.10': { type: 'string' },
          '10.2.11': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '10.2.11.1': { type: 'float' },
              '10.2.11.2': { type: 'float' },
              '10.2.11.3': { type: 'int' },
            },
          },
          '10.2.12': { type: 'string' },
          '10.2.13': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '10.2.13.1': { type: 'checkbox' },
              '10.2.13.2': { type: 'checkbox' },
              '10.2.13.3': { type: 'checkbox' },
              '10.2.13.4': { type: 'checkbox' },
              '10.2.13.5': { type: 'checkbox' },
              '10.2.13.6': { type: 'checkbox' },
              '10.2.13.7': { type: 'checkbox' },
              '10.2.13.8': { type: 'checkbox' },
              '10.2.13.9': { type: 'checkbox' },
              '10.2.13.10': { type: 'checkbox' },
              '10.2.13.10.1': { type: 'string' },
            },
          },
          '10.2.14': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '10.2.14.1': { type: 'checkbox' },
              '10.2.14.2': { type: 'checkbox' },
              '10.2.14.3': { type: 'checkbox' },
              '10.2.14.4': { type: 'checkbox' },
              '10.2.14.5': { type: 'checkbox' },
              '10.2.14.6': { type: 'checkbox' },
              '10.2.14.7': { type: 'checkbox' },
              '10.2.14.8': { type: 'checkbox' },
              '10.2.14.9': { type: 'checkbox' },
              '10.2.14.9.1': { type: 'string' },
            },
          },
          '10.2.15': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '10.2.15.1': { type: 'checkbox' },
              '10.2.15.2': {
                type: 'check_combo',
                subvalues: {
                  '10.2.15.3': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.4': { type: 'int' } },
                  },
                  '10.2.15.5': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.6': { type: 'int' } },
                  },
                  '10.2.15.7': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.8': { type: 'int' } },
                  },
                },
              },
              '10.2.15.9': {
                type: 'check_combo',
                subvalues: {
                  '10.2.15.10': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.11': { type: 'int' } },
                  },
                  '10.2.15.12': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.13': { type: 'int' } },
                  },
                  '10.2.15.14': {
                    type: 'check_combo',
                    subvalues: { '10.2.15.15': { type: 'int' } },
                  },
                },
              },
              '10.2.15.16': {
                type: 'check_combo',
                subvalues: { '10.2.15.16.1': { type: 'string' } },
              },
            },
          },
          '10.2.16': { type: 'string' },
          '10.2.17': { type: 'string' },
          '10.2.18': { type: 'string' },
          '10.2.19': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '10.2.19.1': { type: 'int' },
              '10.2.19.2': { type: 'int' },
              '10.2.19.3': { type: 'int' },
            },
          },
          '10.2.20': {
            type: 'group',
            label: 'Basement',
            values: {
              '10.2.20.1': { type: 'checkbox' },
              '10.2.20.2': { type: 'checkbox' },
              '10.2.20.3': { type: 'checkbox' },
              '10.2.20.4': { type: 'checkbox' },
              '10.2.20.5': { type: 'checkbox' },
              '10.2.20.6': { type: 'checkbox' },
            },
          },
          '10.2.21': { type: 'string' },
          '10.2.22': {
            type: 'group',
            label: 'Attic',
            values: {
              '10.2.22.1': { type: 'checkbox' },
              '10.2.22.2': { type: 'checkbox' },
              '10.2.22.3': { type: 'checkbox' },
              '10.2.22.4': { type: 'checkbox' },
            },
          },
          '10.2.23': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '10.2.23.1': { type: 'checkbox' },
              '10.2.23.2': { type: 'checkbox' },
              '10.2.23.3': { type: 'checkbox' },
            },
          },
          '10.2.24': { type: 'string' },
          '10.2.25': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '10.2.25.1': { type: 'checkbox' },
              '10.2.25.2': { type: 'checkbox' },
              '10.2.25.3': { type: 'checkbox' },
              '10.2.25.4': { type: 'checkbox' },
              '10.2.25.5': { type: 'checkbox' },
              '10.2.25.6': { type: 'checkbox' },
              '10.2.25.7': { type: 'checkbox' },
              '10.2.25.7.1': { type: 'string' },
            },
          },
          '10.2.26': { type: 'string' },
          '10.2.27': { type: 'string' },
          '10.2.28': { type: 'string' },
          '10.2.29': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '10.2.29.1': { type: 'checkbox' },
              '10.2.29.2': { type: 'checkbox' },
              '10.2.29.3': { type: 'checkbox' },
              '10.2.29.4': { type: 'checkbox' },
            },
          },
          '10.2.30': {
            type: 'group',
            label: 'Water Disposal',
            values: {
              '10.2.30.1': { type: 'checkbox' },
              '10.2.30.2': { type: 'checkbox' },
              '10.2.30.3': { type: 'checkbox' },
              '10.2.30.4': { type: 'checkbox' },
              '10.2.30.4.1': { type: 'string' },
            },
          },
          '10.2.31': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '10.2.31.1': { type: 'checkbox' },
              '10.2.31.2': { type: 'checkbox' },
              '10.2.31.3': { type: 'checkbox' },
              '10.2.31.4': { type: 'checkbox' },
              '10.2.31.5': { type: 'checkbox' },
              '10.2.31.5.1': { type: 'string' },
            },
          },
          '10.2.32': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '10.2.32.1': { type: 'checkbox' },
              '10.2.32.2': { type: 'checkbox' },
              '10.2.32.3': { type: 'checkbox' },
              '10.2.32.4': { type: 'checkbox' },
              '10.2.32.5': { type: 'checkbox' },
              '10.2.32.6': { type: 'checkbox' },
              '10.2.32.7': { type: 'checkbox' },
              '10.2.32.8': { type: 'checkbox' },
              '10.2.32.9': { type: 'checkbox' },
              '10.2.32.10': { type: 'checkbox' },
              '10.2.32.11': { type: 'checkbox' },
              '10.2.32.12': { type: 'checkbox' },
              '10.2.32.13': { type: 'checkbox' },
              '10.2.32.13.1': { type: 'string' },
            },
          },
          '10.2.33': {
            type: 'group',
            label: 'Location',
            values: {
              '10.2.33.1': { type: 'checkbox' },
              '10.2.33.2': { type: 'checkbox' },
              '10.2.33.3': { type: 'checkbox' },
            },
          },
          '10.2.34': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '10.2.34.1': { type: 'checkbox' },
              '10.2.34.2': { type: 'checkbox' },
              '10.2.34.3': { type: 'checkbox' },
            },
          },
          '10.2.35': {
            type: 'group',
            label: 'View',
            values: {
              '10.2.35.1': { type: 'checkbox' },
              '10.2.35.2': { type: 'checkbox' },
              '10.2.35.3': { type: 'checkbox' },
            },
          },
          '10.2.36': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '10.2.36.1': { type: 'checkbox' },
              '10.2.36.2': { type: 'checkbox' },
              '10.2.36.3': { type: 'checkbox' },
            },
          },
          '10.2.37': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '10.2.37.1': { type: 'checkbox' },
              '10.2.37.2': { type: 'checkbox' },
              '10.2.37.3': { type: 'checkbox' },
            },
          },
          '10.2.38': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '10.2.38.1': { type: 'checkbox' },
              '10.2.38.2': { type: 'checkbox' },
              '10.2.38.3': { type: 'checkbox' },
            },
          },
          '10.2.39': {
            type: 'group',
            label: 'Overall Rating of Listings as Compared to Sale',
            values: {
              '10.2.39.1': { type: 'checkbox' },
              '10.2.39.2': { type: 'checkbox' },
              '10.2.39.3': { type: 'checkbox' },
            },
          },
          '10.2.40': {
            type: 'group',
            label: 'Sales #1',
            values: {
              '10.2.40.1': { type: 'checkbox' },
              '10.2.40.2': { type: 'checkbox' },
              '10.2.40.3': { type: 'string' },
            },
          },
        },
      },
      comparable_listing_2: {
        label: 'Comparable Listing 2',
        reference: 10.3,
        fields: {
          '10.3.1': { type: 'string' },
          '10.3.2': { type: 'string' },
          '10.3.3': { type: 'string' },
          '10.3.4': { type: 'float' },
          '10.3.5': { type: 'float' },
          '10.3.6': { type: 'float' },
          '10.3.7': { type: 'string' },
          '10.3.8': { type: 'string' },
          '10.3.9': { type: 'string' },
          '10.3.10': { type: 'string' },
          '10.3.11': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '10.3.11.1': { type: 'float' },
              '10.3.11.2': { type: 'float' },
              '10.3.11.3': { type: 'int' },
            },
          },
          '10.3.12': { type: 'string' },
          '10.3.13': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '10.3.13.1': { type: 'checkbox' },
              '10.3.13.2': { type: 'checkbox' },
              '10.3.13.3': { type: 'checkbox' },
              '10.3.13.4': { type: 'checkbox' },
              '10.3.13.5': { type: 'checkbox' },
              '10.3.13.6': { type: 'checkbox' },
              '10.3.13.7': { type: 'checkbox' },
              '10.3.13.8': { type: 'checkbox' },
              '10.3.13.9': { type: 'checkbox' },
              '10.3.13.10': { type: 'checkbox' },
              '10.3.13.10.1': { type: 'string' },
            },
          },
          '10.3.14': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '10.3.14.1': { type: 'checkbox' },
              '10.3.14.2': { type: 'checkbox' },
              '10.3.14.3': { type: 'checkbox' },
              '10.3.14.4': { type: 'checkbox' },
              '10.3.14.5': { type: 'checkbox' },
              '10.3.14.6': { type: 'checkbox' },
              '10.3.14.7': { type: 'checkbox' },
              '10.3.14.8': { type: 'checkbox' },
              '10.3.14.9': { type: 'checkbox' },
              '10.3.14.9.1': { type: 'string' },
            },
          },
          '10.3.15': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '10.3.15.1': { type: 'checkbox' },
              '10.3.15.2': {
                type: 'check_combo',
                subvalues: {
                  '10.3.15.3': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.4': { type: 'int' } },
                  },
                  '10.3.15.5': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.6': { type: 'int' } },
                  },
                  '10.3.15.7': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.8': { type: 'int' } },
                  },
                },
              },
              '10.3.15.9': {
                type: 'check_combo',
                subvalues: {
                  '10.3.15.10': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.11': { type: 'int' } },
                  },
                  '10.3.15.12': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.13': { type: 'int' } },
                  },
                  '10.3.15.14': {
                    type: 'check_combo',
                    subvalues: { '10.3.15.15': { type: 'int' } },
                  },
                },
              },
              '10.3.15.16': {
                type: 'check_combo',
                subvalues: { '10.3.15.16.1': { type: 'string' } },
              },
            },
          },
          '10.3.16': { type: 'string' },
          '10.3.17': { type: 'string' },
          '10.3.18': { type: 'string' },
          '10.3.19': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '10.3.19.1': { type: 'int' },
              '10.3.19.2': { type: 'int' },
              '10.3.19.3': { type: 'int' },
            },
          },
          '10.3.20': {
            type: 'group',
            label: 'Basement',
            values: {
              '10.3.20.1': { type: 'checkbox' },
              '10.3.20.2': { type: 'checkbox' },
              '10.3.20.3': { type: 'checkbox' },
              '10.3.20.4': { type: 'checkbox' },
              '10.3.20.5': { type: 'checkbox' },
              '10.3.20.6': { type: 'checkbox' },
            },
          },
          '10.3.21': { type: 'string' },
          '10.3.22': {
            type: 'group',
            label: 'Attic',
            values: {
              '10.3.22.1': { type: 'checkbox' },
              '10.3.22.2': { type: 'checkbox' },
              '10.3.22.3': { type: 'checkbox' },
              '10.3.22.4': { type: 'checkbox' },
            },
          },
          '10.3.23': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '10.3.23.1': { type: 'checkbox' },
              '10.3.23.2': { type: 'checkbox' },
              '10.3.23.3': { type: 'checkbox' },
            },
          },
          '10.3.24': { type: 'string' },
          '10.3.25': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '10.3.25.1': { type: 'checkbox' },
              '10.3.25.2': { type: 'checkbox' },
              '10.3.25.3': { type: 'checkbox' },
              '10.3.25.4': { type: 'checkbox' },
              '10.3.25.5': { type: 'checkbox' },
              '10.3.25.6': { type: 'checkbox' },
              '10.3.25.7': { type: 'checkbox' },
              '10.3.25.7.1': { type: 'string' },
            },
          },
          '10.3.26': { type: 'string' },
          '10.3.27': { type: 'string' },
          '10.3.28': { type: 'string' },
          '10.3.29': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '10.3.29.1': { type: 'checkbox' },
              '10.3.29.2': { type: 'checkbox' },
              '10.3.29.3': { type: 'checkbox' },
              '10.3.29.4': { type: 'checkbox' },
            },
          },
          '10.3.30': {
            type: 'group',
            label: 'Water Disposal',
            values: {
              '10.3.30.1': { type: 'checkbox' },
              '10.3.30.2': { type: 'checkbox' },
              '10.3.30.3': { type: 'checkbox' },
              '10.3.30.4': { type: 'checkbox' },
              '10.3.30.4.1': { type: 'string' },
            },
          },
          '10.3.31': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '10.3.31.1': { type: 'checkbox' },
              '10.3.31.2': { type: 'checkbox' },
              '10.3.31.3': { type: 'checkbox' },
              '10.3.31.4': { type: 'checkbox' },
              '10.3.31.5': { type: 'checkbox' },
              '10.3.31.5.1': { type: 'string' },
            },
          },
          '10.3.32': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '10.3.32.1': { type: 'checkbox' },
              '10.3.32.2': { type: 'checkbox' },
              '10.3.32.3': { type: 'checkbox' },
              '10.3.32.4': { type: 'checkbox' },
              '10.3.32.5': { type: 'checkbox' },
              '10.3.32.6': { type: 'checkbox' },
              '10.3.32.7': { type: 'checkbox' },
              '10.3.32.8': { type: 'checkbox' },
              '10.3.32.9': { type: 'checkbox' },
              '10.3.32.10': { type: 'checkbox' },
              '10.3.32.11': { type: 'checkbox' },
              '10.3.32.12': { type: 'checkbox' },
              '10.3.32.13': { type: 'checkbox' },
              '10.3.32.13.1': { type: 'string' },
            },
          },
          '10.3.33': {
            type: 'group',
            label: 'Location',
            values: {
              '10.3.33.1': { type: 'checkbox' },
              '10.3.33.2': { type: 'checkbox' },
              '10.3.33.3': { type: 'checkbox' },
            },
          },
          '10.3.34': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '10.3.34.1': { type: 'checkbox' },
              '10.3.34.2': { type: 'checkbox' },
              '10.3.34.3': { type: 'checkbox' },
            },
          },
          '10.3.35': {
            type: 'group',
            label: 'View',
            values: {
              '10.3.35.1': { type: 'checkbox' },
              '10.3.35.2': { type: 'checkbox' },
              '10.3.35.3': { type: 'checkbox' },
            },
          },
          '10.3.36': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '10.3.36.1': { type: 'checkbox' },
              '10.3.36.2': { type: 'checkbox' },
              '10.3.36.3': { type: 'checkbox' },
            },
          },
          '10.3.37': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '10.3.37.1': { type: 'checkbox' },
              '10.3.37.2': { type: 'checkbox' },
              '10.3.37.3': { type: 'checkbox' },
            },
          },
          '10.3.38': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '10.3.38.1': { type: 'checkbox' },
              '10.3.38.2': { type: 'checkbox' },
              '10.3.38.3': { type: 'checkbox' },
            },
          },
          '10.3.39': {
            type: 'group',
            label: 'Overall Rating of Listings as Compared to Sale',
            values: {
              '10.3.39.1': { type: 'checkbox' },
              '10.3.39.2': { type: 'checkbox' },
              '10.3.39.3': { type: 'checkbox' },
            },
          },
          '10.3.40': {
            type: 'group',
            label: 'Sales #2',
            values: {
              '10.3.40.1': { type: 'checkbox' },
              '10.3.40.2': { type: 'checkbox' },
              '10.3.40.3': { type: 'string' },
            },
          },
        },
      },
      comparable_listing_3: {
        label: 'Comparable Listing 3',
        reference: 10.4,
        fields: {
          '10.4.1': { type: 'string' },
          '10.4.2': { type: 'string' },
          '10.4.3': { type: 'string' },
          '10.4.4': { type: 'float' },
          '10.4.5': { type: 'float' },
          '10.4.6': { type: 'float' },
          '10.4.7': { type: 'string' },
          '10.4.8': { type: 'string' },
          '10.4.9': { type: 'string' },
          '10.4.10': { type: 'string' },
          '10.4.11': {
            type: 'group',
            label: 'Previous listing history for last 12 months',
            values: {
              '10.4.11.1': { type: 'float' },
              '10.4.11.2': { type: 'float' },
              '10.4.11.3': { type: 'int' },
            },
          },
          '10.4.12': { type: 'string' },
          '10.4.13': {
            type: 'group',
            label: 'Exterior Finish',
            values: {
              '10.4.13.1': { type: 'checkbox' },
              '10.4.13.2': { type: 'checkbox' },
              '10.4.13.3': { type: 'checkbox' },
              '10.4.13.4': { type: 'checkbox' },
              '10.4.13.5': { type: 'checkbox' },
              '10.4.13.6': { type: 'checkbox' },
              '10.4.13.7': { type: 'checkbox' },
              '10.4.13.8': { type: 'checkbox' },
              '10.4.13.9': { type: 'checkbox' },
              '10.4.13.10': { type: 'checkbox' },
              '10.4.13.10.1': { type: 'string' },
            },
          },
          '10.4.14': {
            type: 'group',
            label: 'Roof Type',
            values: {
              '10.4.14.1': { type: 'checkbox' },
              '10.4.14.2': { type: 'checkbox' },
              '10.4.14.3': { type: 'checkbox' },
              '10.4.14.4': { type: 'checkbox' },
              '10.4.14.5': { type: 'checkbox' },
              '10.4.14.6': { type: 'checkbox' },
              '10.4.14.7': { type: 'checkbox' },
              '10.4.14.8': { type: 'checkbox' },
              '10.4.14.9': { type: 'checkbox' },
              '10.4.14.9.1': { type: 'string' },
            },
          },
          '10.4.15': {
            type: 'group',
            label: 'Car Storage/Type',
            values: {
              '10.4.15.1': { type: 'checkbox' },
              '10.4.15.2': {
                type: 'check_combo',
                subvalues: {
                  '10.4.15.3': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.4': { type: 'int' } },
                  },
                  '10.4.15.5': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.6': { type: 'int' } },
                  },
                  '10.4.15.7': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.8': { type: 'int' } },
                  },
                },
              },
              '10.4.15.9': {
                type: 'check_combo',
                subvalues: {
                  '10.4.15.10': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.11': { type: 'int' } },
                  },
                  '10.4.15.12': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.13': { type: 'int' } },
                  },
                  '10.4.15.14': {
                    type: 'check_combo',
                    subvalues: { '10.4.15.15': { type: 'int' } },
                  },
                },
              },
              '10.4.15.16': {
                type: 'check_combo',
                subvalues: { '10.4.15.16.1': { type: 'string' } },
              },
            },
          },
          '10.4.16': { type: 'string' },
          '10.4.17': { type: 'string' },
          '10.4.18': { type: 'string' },
          '10.4.19': {
            type: 'group',
            label: 'Above Grade Room Count',
            values: {
              '10.4.19.1': { type: 'int' },
              '10.4.19.2': { type: 'int' },
              '10.4.19.3': { type: 'int' },
            },
          },
          '10.4.20': {
            type: 'group',
            label: 'Basement',
            values: {
              '10.4.20.1': { type: 'checkbox' },
              '10.4.20.2': { type: 'checkbox' },
              '10.4.20.3': { type: 'checkbox' },
              '10.4.20.4': { type: 'checkbox' },
              '10.4.20.5': { type: 'checkbox' },
              '10.4.20.6': { type: 'checkbox' },
            },
          },
          '10.4.21': { type: 'string' },
          '10.4.22': {
            type: 'group',
            label: 'Attic',
            values: {
              '10.4.22.1': { type: 'checkbox' },
              '10.4.22.2': { type: 'checkbox' },
              '10.4.22.3': { type: 'checkbox' },
              '10.4.22.4': { type: 'checkbox' },
            },
          },
          '10.4.23': {
            type: 'group',
            label: 'Attic Access',
            values: {
              '10.4.23.1': { type: 'checkbox' },
              '10.4.23.2': { type: 'checkbox' },
              '10.4.23.3': { type: 'checkbox' },
            },
          },
          '10.4.24': { type: 'string' },
          '10.4.25': {
            type: 'group',
            label: 'Detached Structures',
            values: {
              '10.4.25.1': { type: 'checkbox' },
              '10.4.25.2': { type: 'checkbox' },
              '10.4.25.3': { type: 'checkbox' },
              '10.4.25.4': { type: 'checkbox' },
              '10.4.25.5': { type: 'checkbox' },
              '10.4.25.6': { type: 'checkbox' },
              '10.4.25.7': { type: 'checkbox' },
              '10.4.25.7.1': { type: 'string' },
            },
          },
          '10.4.26': { type: 'string' },
          '10.4.27': { type: 'string' },
          '10.4.28': { type: 'string' },
          '10.4.29': {
            type: 'group',
            label: 'Water Supply',
            values: {
              '10.4.29.1': { type: 'checkbox' },
              '10.4.29.2': { type: 'checkbox' },
              '10.4.29.3': { type: 'checkbox' },
              '10.4.29.4': { type: 'checkbox' },
            },
          },
          '10.4.30': {
            type: 'group',
            label: 'Water Disposal',
            values: {
              '10.4.30.1': { type: 'checkbox' },
              '10.4.30.2': { type: 'checkbox' },
              '10.4.30.3': { type: 'checkbox' },
              '10.4.30.4': { type: 'checkbox' },
              '10.4.30.4.1': { type: 'string' },
            },
          },
          '10.4.31': {
            type: 'group',
            label: 'Type of Air Conditioning',
            values: {
              '10.4.31.1': { type: 'checkbox' },
              '10.4.31.2': { type: 'checkbox' },
              '10.4.31.3': { type: 'checkbox' },
              '10.4.31.4': { type: 'checkbox' },
              '10.4.31.5': { type: 'checkbox' },
              '10.4.31.5.1': { type: 'string' },
            },
          },
          '10.4.32': {
            type: 'group',
            label: 'Type of Heating System',
            values: {
              '10.4.32.1': { type: 'checkbox' },
              '10.4.32.2': { type: 'checkbox' },
              '10.4.32.3': { type: 'checkbox' },
              '10.4.32.4': { type: 'checkbox' },
              '10.4.32.5': { type: 'checkbox' },
              '10.4.32.6': { type: 'checkbox' },
              '10.4.32.7': { type: 'checkbox' },
              '10.4.32.8': { type: 'checkbox' },
              '10.4.32.9': { type: 'checkbox' },
              '10.4.32.10': { type: 'checkbox' },
              '10.4.32.11': { type: 'checkbox' },
              '10.4.32.12': { type: 'checkbox' },
              '10.4.32.13': { type: 'checkbox' },
              '10.4.32.13.1': { type: 'string' },
            },
          },
          '10.4.33': {
            type: 'group',
            label: 'Location',
            values: {
              '10.4.33.1': { type: 'checkbox' },
              '10.4.33.2': { type: 'checkbox' },
              '10.4.33.3': { type: 'checkbox' },
            },
          },
          '10.4.34': {
            type: 'group',
            label: 'Lot Characteristics',
            values: {
              '10.4.34.1': { type: 'checkbox' },
              '10.4.34.2': { type: 'checkbox' },
              '10.4.34.3': { type: 'checkbox' },
            },
          },
          '10.4.35': {
            type: 'group',
            label: 'View',
            values: {
              '10.4.35.1': { type: 'checkbox' },
              '10.4.35.2': { type: 'checkbox' },
              '10.4.35.3': { type: 'checkbox' },
            },
          },
          '10.4.36': {
            type: 'group',
            label: 'Floor Plan Utility',
            values: {
              '10.4.36.1': { type: 'checkbox' },
              '10.4.36.2': { type: 'checkbox' },
              '10.4.36.3': { type: 'checkbox' },
            },
          },
          '10.4.37': {
            type: 'group',
            label: "Ext. Condition's Appeal",
            values: {
              '10.4.37.1': { type: 'checkbox' },
              '10.4.37.2': { type: 'checkbox' },
              '10.4.37.3': { type: 'checkbox' },
            },
          },
          '10.4.38': {
            type: 'group',
            label: "Int. Condition's Appeal",
            values: {
              '10.4.38.1': { type: 'checkbox' },
              '10.4.38.2': { type: 'checkbox' },
              '10.4.38.3': { type: 'checkbox' },
            },
          },
          '10.4.39': {
            type: 'group',
            label: 'Overall Rating of Listings as Compared to Sale',
            values: {
              '10.4.39.1': { type: 'checkbox' },
              '10.4.39.2': { type: 'checkbox' },
              '10.4.39.3': { type: 'checkbox' },
            },
          },
          '10.4.40': {
            type: 'group',
            label: 'Sales #3',
            values: {
              '10.4.40.1': { type: 'checkbox' },
              '10.4.40.2': { type: 'checkbox' },
              '10.4.40.3': { type: 'string' },
            },
          },
        },
      },
    },
  },
  6: {
    sections: {
      most_likely_buyers: {
        label: 'Most Likely Buyer(s)',
        reference: 11,
        fields: {
          11.1: {
            type: 'group',
            label: 'Check all that apply',
            values: {
              11.1: { type: 'checkbox' },
              11.2: { type: 'checkbox' },
              11.3: { type: 'checkbox' },
              11.4: { type: 'checkbox' },
              11.5: { type: 'checkbox' },
              11.6: { type: 'checkbox' },
              11.7: { type: 'checkbox' },
              11.8: { type: 'checkbox' },
              11.9: { type: 'checkbox' },
              '11.10': { type: 'checkbox' },
              11.11: { type: 'checkbox' },
              11.12: { type: 'checkbox' },
              '11.12.1': { type: 'string' },
            },
          },
        },
      },
      marketing_strategy: {
        label: 'Marketing Strategy',
        reference: 12,
        fields: {
          12.1: {
            type: 'group',
            label: 'Challenges to getting this property sold',
            values: {
              '12.1.1': { type: 'string' },
              '12.1.2': { type: 'string' },
              '12.1.3': { type: 'string' },
              '12.1.4': { type: 'string' },
              '12.1.5': { type: 'string' },
            },
          },
          12.2: {
            type: 'group',
            label: 'Actions necessary to address the challenges',
            values: {
              '12.2.1': { type: 'string' },
              '12.2.2': { type: 'string' },
              '12.2.3': { type: 'string' },
              '12.2.4': { type: 'string' },
              '12.2.5': { type: 'string' },
            },
          },
          12.3: {
            type: 'group',
            label: 'Creative ideas to be used in marketing this property',
            values: {
              '12.3.1': { type: 'string' },
              '12.3.2': { type: 'string' },
              '12.3.3': { type: 'string' },
              '12.3.4': { type: 'string' },
              '12.3.5': { type: 'string' },
            },
          },
          12.4: { type: 'string' },
        },
      },
      value_analysis: {
        label: 'Value Analysis',
        reference: 13,
        fields: {
          13.1: { type: 'float' },
          13.2: { type: 'float' },
          13.3: { type: 'int' },
        },
      },
      signatures: {
        label: 'Signatures',
        reference: 14,
        fields: {
          14.1: { type: 'string' },
          14.2: { type: 'string' },
          14.3: { type: 'string' },
          14.4: { type: 'string' },
          14.5: { type: 'string' },
          14.6: { type: 'string' },
          14.7: { type: 'string' },
          14.8: { type: 'string' },
        },
      },
    },
  },
};
