import React, { useState, useEffect } from 'react';
import { Modal, Label, Button } from 'flowbite-react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import Report from './Report';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useGetPreviewReviewSheet, useSaveToReware } from 'hooks';

const StyledButton = styled.div`
  & button {
    width: 100%;
    background-color: #1c64f2;
  }
`;
const Header = styled.div`
  & h3 {
    padding: 8px;
  }
`;
const StyledButtonApply = styled.div`
  & button {
    border: 1px solid #3f83f8;
  }
  & span {
    color: #3f83f8;
  }
`;

const SignupSchema = Yup.object().shape({
  BMA1PartnerScore: Yup.number()
    .min(0, 'Scores must be between 0 and 5.')
    .max(5, 'Scores must be between 0 and 5.'),
  BMA2PartnerScore: Yup.number()
    .min(0, 'Scores must be between 0 and 5.')
    .max(5, 'Scores must be between 0 and 5.'),
});

const ModalMPreferred = ({ isOpenModal, setIsOpenModal, fileId, userToken }) => {
  const [enableFetching, setEnableFetching] = useState(false);
  const [params, setParams] = useState({});
  const [paramsReware, setParamsReware] = useState({});
  const [enableSaveReware, setEnableSaveReware] = useState(false);
  const [errors, setErrors] = useState(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      BMA1PartnerScore: '',
      BMA2PartnerScore: '',
    },
    validationSchema: SignupSchema,
    onSubmit: values => {
      setParams(values);
    },
    enableReinitialize: true,
  });

  const handleCloseModal = () => {
    setIsOpenModal(!isOpenModal);
    formik.resetForm();
    setParams({});
    setEnableSaveReware(false);
  };

  const handleSaveToReware = () => {
    setParamsReware(formik.values);
    setEnableSaveReware(true);
  };

  useEffect(() => {
    if (!isEmpty(formik.errors)) {
      setErrors(formik.errors);
    } else setErrors(null);
  }, [formik]);

  useEffect(() => {
    if (userToken && isOpenModal) {
      setEnableFetching(true);
    }
  }, [userToken, isOpenModal]);

  const { isLoading, isFetching, data } = useGetPreviewReviewSheet(
    fileId,
    userToken,
    params,
    enableFetching,
  );

  useEffect(() => {
    if (isOpenModal) {
      queryClient.invalidateQueries('GetPreviewReviewSheet');
    }
  }, [isOpenModal, queryClient]);

  useEffect(() => {
    queryClient.invalidateQueries('SaveToReware');
  }, [enableSaveReware, queryClient]);

  useSaveToReware(
    fileId,
    userToken,
    paramsReware,
    enableFetching,
    enableSaveReware,
    handleCloseModal,
    navigate,
  );

  return (
    <Modal
      show={isOpenModal}
      size="4xl"
      popup={true}
      onClose={() => handleCloseModal()}
      id="modal-preferred"
    >
      <Header>
        <Modal.Header>Report Preview</Modal.Header>
      </Header>
      <Modal.Body>
        <div className="space-y-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex mb-2">
              <div className="w-2/5 pl-5 pr-2">
                <div className="mb-2">
                  <Label
                    className="font-medium text-sm not-italic"
                    htmlFor="BMA1PartnerScore"
                    value="Override Agent 1 Score"
                  />
                </div>
                <input
                  type="number"
                  name="BMA1PartnerScore"
                  id="BMA1PartnerScore"
                  min="1"
                  max="5"
                  onChange={formik.handleChange}
                  value={formik.values.BMA1PartnerScore}
                  className={
                    errors?.BMA1PartnerScore
                      ? 'mb-1 bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  }
                />
                <div className="h-6">
                  {errors?.BMA1PartnerScore && (
                    <p className="text-sm text-red-600 dark:text-red-500">
                      {errors?.BMA1PartnerScore}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-2/5 pr-5">
                <div className="mb-2">
                  <Label
                    className="font-medium text-sm not-italic"
                    htmlFor="BMA2PartnerScore"
                    value="Override Agent 2 Score"
                  />
                </div>
                <input
                  name="BMA2PartnerScore"
                  id="BMA2PartnerScore"
                  type="number"
                  min="1"
                  max="5"
                  onChange={formik.handleChange}
                  value={formik.values.BMA2PartnerScore}
                  className={
                    errors?.BMA2PartnerScore
                      ? 'mb-1 bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  }
                />{' '}
                <div className="h-6">
                  {errors?.BMA2PartnerScore && (
                    <p className="text-sm text-red-600 dark:text-red-500">
                      {' '}
                      {errors?.BMA2PartnerScore}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-1/5">
                <div className="pt-8">
                  <StyledButtonApply>
                    <Button
                      color={'gray'}
                      disabled={errors}
                      onClick={formik.handleSubmit}
                    >
                      Apply Overrides
                    </Button>
                  </StyledButtonApply>
                </div>
              </div>
            </div>

            {(isLoading || isFetching) && (
              <span className="w-full flex justify-center">Files Loading...</span>
            )}
            <div className="h-50v mb-5 overflow-scroll">
              {data?.data && <Report reportData={data} />}
            </div>

            <div className="w-full flex justify-end">
              <StyledButton>
                <Button disabled={errors} onClick={handleSaveToReware}>
                  Save to Resware
                </Button>
              </StyledButton>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
ModalMPreferred.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  userToken: PropTypes.string,
};
export default ModalMPreferred;
