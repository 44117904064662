
import { listFiles } from 'Api/file';
import { useQuery } from 'react-query';

export const useGetListFiles = (currentPage, searchQuery, userToken, enableFetching) => {
  const { isLoading, isFetching, data, isError } = useQuery(
    ['ListFiles', currentPage, searchQuery, userToken],
    listFiles,
    {
      staleTime: Infinity,
      enabled: enableFetching,
    },
  );
  return { isLoading, isFetching, data, isError};
};
