import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const listPartnerDocuments = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    `${apiEndoints.LIST_PARTNERS}/${queryKey[1]}/Documents`,
    { authorization: queryKey[3] },
    {
      page: queryKey[2],
    },
    10,
  );
};

listPartnerDocuments.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { listPartnerDocuments };
