import DataTable from 'components/DataTable';
import useUser from 'hooks/useUser';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { Pagination } from 'flowbite-react';
import { pageData } from 'utils/constants';
import ModalPartner from 'components/pages/Partner/ModalPartner';
import { useGetListPartners, useGetPartner } from 'hooks';

const PartnerListPageContent = ({ searchQuery }) => {
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [enableFetching, setEnableFetching] = useState(false);
  const [enableFetchingPartner, setEnableFetchingPartner] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [partnerEntries, setPartnerEntries] = useState([]);
  const [partnerData, setPartnerData] = useState(null);
  const { userToken } = useUser();

  const { isLoading, data, isError } = useGetListPartners(
    currentPage,
    searchQuery,
    userToken,
    enableFetching,
  );

  const { dataPartner } = useGetPartner(
    userToken,
    selectedPartner?.id,
    enableFetchingPartner,
  );

  useEffect(() => {
    if (userToken) {
      setEnableFetching(true);
    }
  }, [userToken]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (userToken && selectedPartner) {
      setEnableFetchingPartner(true);
    } else setEnableFetchingPartner(false);
  }, [userToken, selectedPartner]);

  useEffect(() => {
    setPartnerData(dataPartner?.data);
  }, [dataPartner]);

  useEffect(() => {
    const partnerEntriesTemp = data ? data.data.entities : [];
    setPartnerEntries(partnerEntriesTemp);
    const pageCountTemp = data ? Math.ceil(data.data.totalEntities / 10) : 1;
    setPageCount(pageCountTemp);
  }, [data]);

  const handleOpenModal = row => {
    setIsOpenModal(true);
    setSelectedPartner(row);
  };

  if (isLoading) {
    return (
      <span className="w-full flex justify-center" data-testid="test-loader">
        Partners Loading...
      </span>
    );
  }

  if (isError) {
    return (
      <span className="w-full flex justify-center" data-testid="test-error">
        An Error occured When fetching the data
      </span>
    );
  }

  return (
    <div>
      <div className="mb-8 block">
        <DataTable
          columns={pageData.PARTNERS.TABLE_COLUMNS_DATA}
          data={partnerEntries}
          onRowClicked={handleOpenModal}
        />
      </div>
      {pageCount > 1 && (
        <div className="flex items-center justify-center mb-8">
          <Pagination
            data-testid="test-pagination"
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={page => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
      {partnerData && (
        <ModalPartner
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          selectedPartner={selectedPartner}
          partnerData={partnerData}
          setSelectedPartner={setSelectedPartner}
          setEnableFetchingPartner={setEnableFetchingPartner}
        />
      )}
    </div>
  );
};

PartnerListPageContent.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default PartnerListPageContent;
