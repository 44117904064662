import { useNavigate } from 'react-router-dom';

export const useNavigatePage = path => {
  const navigate = useNavigate();

  const handleNavigate = row => {
    navigate(`${path}/${row.id}`);
  };

  return {
    handleNavigate,
  };
};
