import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const listFiles = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    queryKey[2] === '' ? apiEndoints.LIST_FILES : apiEndoints.LIST_QUERIED_FILES,
    { authorization: queryKey[3] },
    {
      page: queryKey[1],
      ...(queryKey[2] !== '' && { query: queryKey[2] }),
    },
    10,
  );
};

listFiles.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { listFiles };
