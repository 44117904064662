import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from 'router/routes';
import GuardedRoute from 'router/guardedRoute';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, key) => {
          if (route.isGuarded) {
            return (
              <Route
                exact
                path={route.path}
                element={<GuardedRoute path={route.path} />}
                key={`guarded-route-key-${key}`}
              >
                {getFinalRoute(route, key)}
              </Route>
            );
          }

          return getFinalRoute(route, key);
        })}
      </Routes>
    </BrowserRouter>
  );
};

const getFinalRoute = (route, key) => {
  if (route.children.length > 0) {
    <Route exact path={route.path} element={route.component} key={`route-key-${key}`}>
      {route.children.map((child, childKey) => {
        return (
          <Route
            path={child.path}
            element={child.component}
            key={`child-route-key-${childKey}`}
          />
        );
      })}
    </Route>;
  }

  return (
    <Route exact path={route.path} element={route.component} key={`route-key-${key}`} />
  );
};

export default Router;
