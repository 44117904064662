import DataTable from 'components/DataTable';
import useUser from 'hooks/useUser';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { Pagination } from 'flowbite-react';
import { formatDate } from 'utils/utils';
import { pageData, urlRouter, statusFile } from 'utils/constants';
import { useGetListFiles } from 'hooks';
import { useNavigatePage } from 'hooks/useNavigatePage';

const FileListPageContent = ({ searchQuery }) => {
  const { handleNavigate } = useNavigatePage(urlRouter.FILE_REVIEW_PAGE);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [enableFetching, setEnableFetching] = useState(false);

  const { userToken } = useUser();

  const handleStatus = status => {
    // eslint-disable-next-line default-case
    switch (status) {
      case statusFile.OPEN:
        return statusFile.OPEN.toUpperCase();
      case statusFile.SAVED_REVIEW_SHEET:
        return statusFile.REPORT_SAVED;
      case statusFile.CLOSED:
        return statusFile.CLOSED.toUpperCase();
    }
  };

  useEffect(() => {
    userToken && setEnableFetching(true);
  }, [userToken]);

  const [fileEntries, setFileEntries] = useState([]);
  const { isLoading, isFetching, data, isError } = useGetListFiles(
    currentPage,
    searchQuery,
    userToken,
    enableFetching,
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    const fileEntriesTemp = data
      ? data.data.entities.map(entity => {
          const verifiedDocuments = entity.documents.filter(
            document => document.verified,
          );
          return {
            id: entity.id,
            fileNumber: entity.reswareFileNumber,
            address: entity.address,
            statusTitle: handleStatus(entity.status),
            file: `${entity.documents.length} BMA Document${
              entity.documents.length !== 1 ? 's' : ''
            }, ${verifiedDocuments.length} Verified`,
            date: formatDate(entity.created),
            status: entity.status,
            isEmpty: entity.documents.length === 0,
          };
        })
      : [];
    setFileEntries(fileEntriesTemp);
    const pageCountTemp = data ? Math.ceil(data.data.totalEntities / 10) : 1;
    setPageCount(pageCountTemp);
  }, [data]);

  if (isLoading || isFetching) {
    return (
      <span data-testid="test-loader" className="w-full flex justify-center">
        Files Loading...
      </span>
    );
  }

  if (isError) {
    return (
      <span data-testid="test-error" className="w-full flex justify-center">
        An Error occured When fetching the data
      </span>
    );
  }

  return (
    <div>
      <div className="mb-8 block">
        <DataTable
          columns={pageData.FILE_LIST.TABLE_COLUMNS_DATA}
          data={fileEntries}
          onRowClicked={handleNavigate}
        />
      </div>
      <div
        className={`flex items-center justify-center mb-8 ${pageCount <= 1 && 'hidden'}`}
      >
        <Pagination
          data-testid="test-pagination"
          id="test-pagination"
          currentPage={currentPage}
          totalPages={pageCount}
          onPageChange={page => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

FileListPageContent.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default FileListPageContent;
