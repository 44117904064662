import { getPDF } from 'Api/pdf';
import { useQuery } from 'react-query';

export const useGetPDF = (userToken, documentId) => {
  const { isLoading, data, isError } = useQuery(
    ['GetPDF', userToken, documentId],
    getPDF,
    {
      staleTime: Infinity,
      enabled: !!userToken,
    },
  );
  return { isLoading, data, isError };
};
