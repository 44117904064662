import React, { useState } from 'react';
import Pagination from './Pagination.jsx';
import SectionItem from './SectionItem.jsx';
import { useFormik } from 'formik';
import useUser from 'hooks/useUser';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import { type } from './typeMapping';
import { compareVersions } from 'compare-versions';
import { useEffect } from 'react';
import { Button } from 'flowbite-react';
import {
  useVerifyDocument,
  useAcquireLockDocument,
  useUnverifyDocument,
  useReleaseLockDocument,
  useUpdateDocument,
} from 'hooks';
import { CONFIDENCE_SCORE } from 'configs/confidence.js';

const StyledSection = styled.div`
  ${({ height }) =>
    css`
      overflow-y: scroll;
      height: calc(100% - ${height}px);
    `}
`;

const StyledButton = styled.div`
  & button {
    width: 100%;
    background-color: #1c64f2;
  }
`;

const StyledVerifyButton = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 0.375rem;
  & button {
    height: 30px;
    background-color: #3f83f8;
  }
`;

const StyledEditButton = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 0.375rem;
  & button {
    height: 30px;

    border: 1px solid #3f83f8;
  }
  & span {
    color: #3f83f8;
  }
`;

const Section = ({
  selectedAgent = {
    contents: [],
  },
  currentPage,
  setCurrentPage,
  totalPage,
  lockedByAnotherUser,
  isLocked,
  setIsLocked,
}) => {
  const { userToken } = useUser();

  const [enableVerify, setEnableVerify] = useState(false);
  const [enableRenderInput, setEnableRenderInput] = useState(true);
  const [checkedLowConfidence, setCheckedLowConfidence] = useState(false);
  const [countLowConfidence, setCountLowConfidence] = useState({});

  const { acquireLockDocument, isLoading } = useAcquireLockDocument(
    setEnableVerify,
    setEnableRenderInput,
  );

  const { unverify } = useUnverifyDocument(
    acquireLockDocument,
    selectedAgent?.id,
    userToken,
  );

  const { releaseLockDocument } = useReleaseLockDocument();

  const { verify } = useVerifyDocument();

  const { update } = useUpdateDocument(verify, selectedAgent.id, userToken);

  const temp = (item, currentPage) => {
    const convertSection = { ...item, elements: [] };

    if (!item) {
      return;
    }
    const pageMap = type[currentPage];
    let targetMap = {};
    Object.keys(pageMap.sections).forEach((itemPageMapped, index) => {
      if (pageMap.sections[itemPageMapped].reference === item.reference) {
        targetMap = pageMap.sections[itemPageMapped];
      }
    });

    if (
      currentPage === 4 ||
      currentPage === 5 ||
      item.reference === 9 ||
      item.reference === 10
    ) {
      targetMap = type[currentPage].sections;
    }

    const listElement = [...item.elements];

    let sortList = [];

    if (currentPage === 2 || item.reference === 5) {
      Object.keys(targetMap.fields).forEach(filedName => {
        const element = targetMap.fields[filedName];

        if (element && element.type === 'group') {
          const found = Object.keys(element.values).some(x =>
            listElement.indexOf(y => y.index === x),
          );

          if (found === true) {
            listElement.push({
              index: filedName,
              label: element.label,
              displayText: element.label,
              type: 'group',
            });
          }
        }
      });

      sortList = listElement
        .filter(item => item.index !== null)
        .sort(function (a, b) {
          return compareVersions(a?.index, b?.index);
        });

      sortList.forEach((itemSort, indexItemSort) => {
        if (indexItemSort > 0) {
          if (itemSort.type === 'group') {
            const targetItemNew = { ...sortList[indexItemSort - 1], haveUnderLine: true };
            Object.assign(sortList[indexItemSort - 1], targetItemNew);
          }
        }
      });
    } else if (
      currentPage === 4 ||
      currentPage === 5 ||
      item.reference === 9 ||
      item.reference === 10
    ) {
      const testTemp = listElement.filter(x => (x.index.match(/\./g) || []).length >= 2);
      Object.keys(targetMap).forEach(sectionKey => {
        Object.keys(targetMap[sectionKey].fields).forEach(filedName => {
          const element = targetMap[sectionKey].fields[filedName];
          if (
            element &&
            element.type === 'group' &&
            testTemp.find(x => x.index.includes(filedName))
          ) {
            listElement.push({
              index: filedName,
              label: element.label,
              displayText: element.label,
              type: 'group',
            });
          }

          const targetItem = listElement.find(x => x.index === filedName);

          if (targetItem) {
            const targetItemNew = { ...targetItem, haveUnderLine: true };
            Object.assign(targetItem, targetItemNew);
          }
        });
      });

      sortList = listElement
        .filter(item => item.index !== null)
        .sort(function (a, b) {
          return compareVersions(a?.index, b?.index);
        });
    } else {
      const testTemp = listElement?.filter(
        x => (x?.index?.match(/\./g) || []).length >= 2,
      );
      Object.keys(targetMap?.fields)?.forEach(filedName => {
        const element = targetMap?.fields[filedName];
        if (
          element &&
          element.type === 'group' &&
          testTemp.find(x => x.index.includes(filedName))
        ) {
          listElement.push({
            index: filedName,
            label: element.label,
            displayText: element.label,
            type: 'group',
          });
        }

        const targetItem = listElement.find(x => x.index === filedName);

        if (targetItem) {
          const targetItemNew = { ...targetItem, haveUnderLine: true };
          Object.assign(targetItem, targetItemNew);
        }
      });

      sortList = listElement
        .filter(item => item.index !== null)
        .sort(function (a, b) {
          return compareVersions(a?.index, b?.index);
        });
    }

    const lastItem = sortList[sortList.length - 1];
    const targetItemNew = { ...lastItem, haveUnderLine: true };
    Object.assign(lastItem, targetItemNew);
    convertSection.elements = sortList;

    return convertSection;
  };

  const initialValues = selectedAgent?.contents?.map((page, key) => {
    const pageConverted = key + 1;
    const page2 = {
      ...page,
      sections: page?.sections
        ?.map(item => temp(item, pageConverted))
        .map(section => {
          const hasLowConfidence = section.elements.find(
            el => el.confidence && el.confidence < CONFIDENCE_SCORE,
          )
            ? true
            : false;
          return {
            ...section,
            hasLowConfidence,
          };
        }),
    };

    return page2;
  });

  const formik = useFormik({
    initialValues: {
      contents: initialValues,
    },
    onSubmit: values => {
      const convertValues = {
        ...values,
        contents: values.contents.map(page => {
          return {
            ...page,
            sections: page.sections.map(section => {
              return {
                ...section,
                elements: section.elements.filter(element => element?.type !== 'group'),
              };
            }),
          };
        }),
      };
      update([selectedAgent.id, userToken, convertValues]);
    },
    enableReinitialize: true,
  });

  const handleBeginVerification = () => {
    setEnableRenderInput(false);
    acquireLockDocument([selectedAgent.id, userToken]);
  };

  const handleDiscardChanges = () => {
    releaseLockDocument([selectedAgent.id, userToken]);
    formik.resetForm();
  };

  const handleEdit = () => {
    setIsLocked(true);
    unverify([selectedAgent.id, userToken]);
  };

  useEffect(() => {
    if (lockedByAnotherUser) {
      setEnableVerify(true);
    }
  }, [lockedByAnotherUser]);

  useEffect(() => {
    setEnableRenderInput(true);
    if (selectedAgent?.verified || isLocked) {
      setEnableVerify(true);
    } else {
      setEnableVerify(false);
    }
  }, [selectedAgent, isLocked]);

  useEffect(() => {
    setCurrentPage(1);
  }, [checkedLowConfidence, setCurrentPage]);

  useEffect(() => {
    initialValues.forEach((item, index) => {
      let count = 0;
      item.sections.forEach(section => {
        const lowConfidence = section.elements.filter(
          el => el.confidence < CONFIDENCE_SCORE,
        );
        count = count + lowConfidence.length;
        setCountLowConfidence(countLowConfidence => ({
          ...countLowConfidence,
          [index]: count,
        }));
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className=" relative flex w-full justify-between pt-5 pl-5 pr-5 pb-2">
        <div>
          <div className="mb-3">{`Agent ${selectedAgent?.partnerCompanyId}`}</div>
        </div>
        {!lockedByAnotherUser &&
          enableVerify &&
          enableRenderInput &&
          (selectedAgent?.verified ? (
            <StyledEditButton>
              <Button
                data-testid="test-edit-button"
                disabled={!!lockedByAnotherUser}
                color={'gray'}
                onClick={() => handleEdit()}
              >
                Edit
              </Button>
            </StyledEditButton>
          ) : (
            <StyledVerifyButton>
              <Button
                data-testid="test-verify-button"
                disabled={!enableVerify || !!lockedByAnotherUser}
                onClick={formik.handleSubmit}
              >
                Verify
              </Button>
            </StyledVerifyButton>
          ))}
      </div>
      {enableVerify && !lockedByAnotherUser && (
        <div className="relative  flex items-start mb-5 ml-5 mr-5">
          <label className="inline-flex  min-w-11 min-w-11 cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={checkedLowConfidence}
              onChange={() => setCheckedLowConfidence(!checkedLowConfidence)}
            />
            <div
              className="w-11 min-w-11 h-6 c bg-gray-200
                peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full
                peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 
                after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
            />
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Low-Confidence Fields Only
            </span>
          </label>
        </div>
      )}
      <hr />

      {!isLocked && !enableVerify && !selectedAgent?.verified && !isLoading && (
        <div className="flex justify-center pt-3">
          <StyledButton>
            <Button
              data-testid="test-begin-verification-button"
              onClick={() => handleBeginVerification()}
            >
              Begin Verification
            </Button>
          </StyledButton>
        </div>
      )}

      {isLoading && !enableVerify && (
        <span className="w-full flex justify-center">Loading...</span>
      )}

      {enableVerify &&
        enableRenderInput &&
        (!lockedByAnotherUser ? (
          <>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
            <StyledSection height={selectedAgent?.verified ? 170 : 240}>
              {checkedLowConfidence && countLowConfidence[currentPage - 1] === 0 ? (
                <div className="flex justify-center mt-5">
                  This page has no Low-Confidence fields
                </div>
              ) : (
                initialValues &&
                initialValues[currentPage - 1]?.sections?.map((item, key) => {
                  return (
                    <SectionItem
                      key={`page-${key}`}
                      sectionKey={key}
                      section={item}
                      isOpenSection={
                        checkedLowConfidence && !item.hasLowConfidence ? true : false
                      }
                      formik={formik}
                      currentPage={currentPage}
                      values={formik.values}
                      isVerified={!!selectedAgent?.verified}
                      selectedAgentId={selectedAgent.id}
                      checkedLowConfidence={checkedLowConfidence}
                    />
                  );
                })
              )}
            </StyledSection>
          </>
        ) : (
          <p className="mt-2 text-center" data-testid="test-message-locked">
            This document is being verified by another person.
          </p>
        ))}
      {!lockedByAnotherUser &&
        enableVerify &&
        !selectedAgent?.verified &&
        enableRenderInput && (
          <div className="flex justify-center">
            <div className="absolute mt-3 mb-5 flex justify-center">
              <button
                data-testid="test-discard-changes-button"
                type="submit"
                className="px-3 py-2 text-xs font-medium text-center text-blue-700 rounded-lg hover:text-white border border-blue-500 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => handleDiscardChanges()}
              >
                Discard changes
              </button>
            </div>
          </div>
        )}
    </>
  );
};
Section.propTypes = {
  selectedAgent: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPage: PropTypes.number,
  lockedByAnotherUser: PropTypes.bool,
  setIsLocked: PropTypes.func,
};

export default Section;
