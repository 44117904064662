import dayjs from 'dayjs';
import { Rating } from 'flowbite-react';

const formatDate = date => {
  const { $D, $M, $y, $d } = dayjs(date);

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$D > 9 ? $D : '0' + $D}/${$y} ${
    parseInt(splitTime[0]) > 12
      ? parseInt(splitTime[0]) - 12
      : parseInt(splitTime[0]) === 0
      ? '12'
      : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) >= 12 ? 'pm' : 'am'}`;
};

const renderStar = numStar => {
  const stars = Array.from({ length: numStar }, (_, i) => i + 1);
  return (
    <Rating>
      {stars.map(key => (
        <svg
          key={key}
          aria-hidden="true"
          className="w-5 h-5 text-gray-900"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ))}
    </Rating>
  );
};
export { formatDate, renderStar };
